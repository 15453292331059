import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import NewButton from 'components/Buttons/NewButton';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import AddCostManager from 'forms/AddCostManager';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

import CostManagerItem from '../../Shared/CostManager/CostManagerItem';

const CostManager = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [formName, setFormName] = useState('');
  const loaded = useSelector((state) => state.costManager.loaded);
  const loading = useSelector((state) => state.costManager.loading);
  const costManager = useSelector((state) => state.costManager.costManager);

  useEffect(() => {
    if (!query.id) {
      return;
    }
    dispatch(actions.getTenantCostManager(query.id));
  }, []);

  const handleNew = () => {
    setFormName('');
    setOpen(true);
  };

  const handleClose = () => {
    setFormName('');
    setOpen(false);
  };

  const handleSave = (form) => {
    dispatch(actions.createTenantCostManager(query.id, form)).then(() => {
      dispatch(actions.getTenantCostManager(query.id))
        .then(() => {
          toast.success('Cost Manager Added');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  };

  function handleDeleteCostManager(id) {
    dispatch(actions.deleteTenantCostManager(id)).then(() => {
      dispatch(actions.getTenantCostManager(query.id))
        .then(() => {
          toast.success('Cost Manager Deleted');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  }

  const handleConfirm = (id) => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          content: 'Are you sure you want to delete this Item, this action cannot be undone',
          title: 'Delete Cost Manager',
          handleConfirm: () => {
            handleDeleteCostManager(id);
            dispatch(closeModal());
          }
        }
      })
    );
  };

  if (!loaded || loading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.header}>Tenant Cost Manager</Typography>
        <NewButton onClick={handleNew} label="New" />
        <AddCostManager
          open={open}
          handleClose={handleClose}
          costManager={formName}
          createCostManager={handleSave}
          title="Tenant Cost Manager"
          uniqueCheck={(value) => costManager.find((element) => element.name === value)}
        />
      </Box>

      <Paper className={classes.paper}>
        <Box display="flex">
          <Box display="flex" flexDirection="column" className={classes.boxInner}>
            <Box>
              <Typography variant="h6" className={classes.title}>
                Items:
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>

            {costManager.map((item) => (
              <CostManagerItem
                name={item.name}
                key={item.id}
                id={item.id}
                url={`/Tenants/${query.id}/CostManager/${item.id}`}
                handleDelete={() => handleConfirm(item.id)}
              />
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CostManager;
