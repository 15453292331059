import {DataExportServiceClient} from 'api/data_export_grpc_web_pb';
import {getEnviroment} from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const DataExportClient = new DataExportServiceClient(getEnviroment(env));

enableDevTools([
    DataExportClient
]);