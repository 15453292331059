import React, { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import UploadedFile from 'components/Wizards/UploadedFile';
import UploadModal from 'components/Wizards/UploadModal';
import { UploadFiles, DeleteFile } from 'helpers/firebaseStorage';
import useFireStorage from 'hooks/useFireStorage';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import useStyles from './styles';

const EmptyState = () => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box className={classes.statsIconBox}>
        <DescriptionIcon className={classes.statsIcon} />
      </Box>
      <Box display="flex" flexDirection="column" width={140} textAlign="center" mt={2}>
        <Typography className={classes.emptyText}>No files have been added yet</Typography>
      </Box>
    </Box>
  );
};

const UploadRightBox = ({ pathName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { filesLoading, files, refresh } = useFireStorage(pathName);

  const fileUpload = () => {
    setUploading(true);
    UploadFiles(acceptedFiles, pathName)
      .then(() => {
        setUploading(false);
        setUploadOpen(false);
        refresh();
        toast.success('File Uploaded');
      })
      .catch(() => {
        setUploading(false);
        toast.error('Something Went wrong');
      });
  };

  const fileDelete = (name) => {
    setUploading(true);

    DeleteFile(name, pathName).then(() => {
      refresh();
      setUploading(false);
      toast.success('File Deleted');
    });
  };

  const handleAttachFiles = useCallback(() => {
    setAcceptedFiles([]);
    setUploadOpen(true);
  }, []);

  const handleConfirm = (file) => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Delete file',
          content:
            'Are you sure you want to delete the selected File? This action cannot be undone',
          handleConfirm: () => {
            fileDelete(file);
            dispatch(closeModal());
          }
        }
      })
    );
  };

  return (
    <>
      <Grid item xs={12} sm={4} md={3} style={{ marginTop: 10 }}>
        <Paper className={classes.paper}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              className={classes.responsive}
            >
              <Typography className={classes.filesTitle}>
                {`Files (${files.files.length || 0})`}
              </Typography>
              <Button
                variant="text"
                className={classes.filesAdd}
                onClick={handleAttachFiles}
                disabled={filesLoading || uploading}
              >
                + ADD
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              <Divider className={classes.divider} />
            </Box>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              {filesLoading && <Spinner mini />}
              {!filesLoading && !files.files.length && <EmptyState />}
              {!filesLoading &&
                files.files.length > 0 &&
                files.files.map((file) => (
                  <UploadedFile
                    key={file.url}
                    name={file.name}
                    dateUploaded={file.dateUploaded}
                    url={file.url}
                    handleDelete={() => handleConfirm(file.name)}
                  />
                ))}
            </Box>
          </Box>
        </Paper>
      </Grid>
      <UploadModal
        setAcceptedFiles={setAcceptedFiles}
        open={uploadOpen}
        handleClose={() => setUploadOpen(false)}
        acceptedFiles={acceptedFiles}
        handleSave={fileUpload}
        loading={uploading}
      />
    </>
  );
};

export default React.memo(UploadRightBox);

UploadRightBox.propTypes = {
  pathName: PropTypes.string.isRequired
};
