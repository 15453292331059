import {GetTenantTurnoverRequest, UpdateTenantTurnoverRequest} from 'api/turnover_grpc_web_pb';
import {metaData} from 'config/config';
import {DataTurnoverService} from '../services/turnover';

import * as actionTypes from './actionTypes';

const getTurnoverDataStart = () => ({
    type: actionTypes.GET_TURNOVER_DATA_START
});

const getTurnoverDataSuccess = (data) => ({
    type: actionTypes.GET_TURNOVER_DATA_SUCCESS,
    data
});

const getTurnoverDataFail = (error) => ({
    type: actionTypes.GET_TURNOVER_DATA_FAIL,
    error
});

export const updateTurnover = (tenantID, month, year, turnover) => () => {
    const request = new UpdateTenantTurnoverRequest();
    request.setTenantid(tenantID);
    request.setMonth(month);
    request.setYear(year);
    request.setTurnover(turnover);

    return new Promise((resolve, reject) => {
        DataTurnoverService.updateTenantTurnover(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const getTurnoverData = (assetId, year) => (dispatch) => {
    dispatch(getTurnoverDataStart());
    const request = new GetTenantTurnoverRequest();
    request.setAssetid(assetId);
    request.setYear(year);

    return new Promise((resolve, reject) => {
        DataTurnoverService.getTenantTurnover(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getTurnoverDataFail(err.message));
                reject(err.message);
            } else {
                dispatch(getTurnoverDataSuccess(response.toObject()));
                resolve(response.toObject());
            }
        });
    });
};
