import React from 'react';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner';
import UploadedFile from 'components/Wizards/UploadedFile';
import PropTypes from 'prop-types';

const ProviderFiles = ({ handleOpen, filesLoading, files, handleDelete }) => (
  <Box display="flex" flexWrap="wrap">
    {filesLoading && <Spinner mini />}
    {!filesLoading &&
      files.length > 0 &&
      files.map((file) => (
        <UploadedFile
          key={file.url}
          name={file.name}
          dateUploaded={file.dateUploaded}
          url={file.url}
          handleOpen={handleOpen}
          handleDelete={() => handleDelete(file.name)}
        />
      ))}
  </Box>
);

export default ProviderFiles;

ProviderFiles.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  filesLoading: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired
};
