import React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    padding: theme.spacing(4, 0, 0, 4)
  },
  link: {
    textDecoration: 'none'
  },
  textLink: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.8
    }
  },
  lastTextLink: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    textDecoration: 'none',
    color: '#7D7D7D'
  }
}));

const Crumps = ({ fragments }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.breadContainer}
    >
      <Link to="/" className={classes.link} data-testid="Home">
        <Typography className={classes.textLink}>Home</Typography>
      </Link>
      {fragments.map(({ title, href }, index) => {
        const isLastBreadcrumb = index === fragments.length - 1;
        return (
          <Link
            to={href}
            key={title}
            className={classes.link}
            style={isLastBreadcrumb ? { pointerEvents: 'none' } : {}}
            data-testid={title}
          >
            <Typography className={isLastBreadcrumb ? classes.lastTextLink : classes.textLink}>
              {title}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Crumps;

Crumps.propTypes = {
  fragments: PropTypes.arrayOf(PropTypes.object).isRequired
};
