import { getEnviroment } from 'config/config';

import { UserServiceClient, RoleServiceClient } from '../../api/user_grpc_web_pb';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const RoleService = new RoleServiceClient(ENV);
export const UserService = new UserServiceClient(ENV);

enableDevTools([
    RoleService,
    UserService
]);