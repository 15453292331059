import React from 'react';

import { Box, Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent
} from '@mui/lab';
import { formatDate, getActivityFeedMessage } from 'helpers/utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: theme.fonts.openSans,
    color: theme.palette.text.grey
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 5,
    minHeight: 300,
    overflowY: 'auto',
    maxHeight: 600,
    padding: theme.spacing(1, 4),
    margin: theme.spacing(1),
    marginTop: 34
  },
  statsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20
  },
  statsIconBox: {
    background: theme.palette.background.grey,
    width: 55,
    height: 55,
    borderRadius: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  missing: {
    '&::before': {
      display: 'none'
    }
  },
  root: {},
  connector: {
    marginTop: 20,
    marginBottom: 20,
    height: 25,
    color: '#7D7D7D'
  },
  timeline: {
    color: '#7D7D7D',
    fontSize: 12,
    fontFamily: theme.fonts.openSans
  }
}));

const ActivityFeed = ({ activityFeed, nowrap }) => {
  const classes = useStyles();

  return (
    <Box m={2}>
      <Paper className={classes.paper} style={nowrap ? { boxShadow: 'none' } : {}}>
        <Timeline align="left">
          {activityFeed.length > 0 &&
            activityFeed.map((item, index) => (
              <TimelineItem
                key={item.id}
                classes={{ missingOppositeContent: classes.missing, root: classes.root }}
              >
                <TimelineSeparator>
                  <Box className={classes.statsIconBox}>
                    <AssignmentIcon className={classes.statsIcon} />
                  </Box>
                  {activityFeed.length !== index + 1 && (
                    <TimelineConnector classes={{ root: classes.connector }} />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography className={classes.text}>
                        {`${item.username} ${getActivityFeedMessage(
                          item.action,
                          item.componentname
                        )}`}
                      </Typography>
                      <Typography className={classes.timeline}>
                        {formatDate(item.createddate)}
                      </Typography>
                    </Box>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </Paper>
    </Box>
  );
};

export default ActivityFeed;

ActivityFeed.defaultProps = {
  activityFeed: [],
  nowrap: false
};

ActivityFeed.propTypes = {
  activityFeed: PropTypes.arrayOf(PropTypes.object),
  nowrap: PropTypes.bool
};
