import { getEnviroment } from 'config/config';

import { ScheduleServiceClient } from '../../api/schedule_grpc_web_pb';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const ScheduleService = new ScheduleServiceClient(ENV);

enableDevTools([
    ScheduleService
]);