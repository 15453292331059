import React from 'react';

import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import useStyles from 'styles/forms';

export default function TextInput({ disabled, name, large, label, children, ...rest }) {
  const classes = useStyles();
  return (
    (<TextField
      fullWidth
      label={label}
      variant="outlined"
      className={large ? classes.textFieldXL : classes.textField}
      placeholder={label}
      name={name}
      id={name}
      disabled={disabled}
      {...rest}
      slotProps={{
        input: {
          classes: {
            input: classes.input
          }
        }
      }}>
      {children}
    </TextField>)
  );
}

TextInput.defaultProps = {
  children: null,
  large: false
};

TextInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  children: PropTypes.node
};
