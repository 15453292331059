/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DescriptionIcon from '@mui/icons-material/Description';
import DetailsIcon from '@mui/icons-material/Details';
import EuroIcon from '@mui/icons-material/Euro';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CustomIcon from 'components/CustomIcon';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: theme.palette.text.grey,
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1)
  },
  details: {
    fontSize: 16,
    color: '#FFF',
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8'
    }
  },
  cominSoonDetails: {
    fontSize: 16,
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1),
    color: theme.palette.accent.main,
    cursor: 'pointer'
  },
  drawerPaper: {
    width: 226,
    top: 'unset',
    backgroundColor: '#1B2740',
    '& .Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      }
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  light: {
    backgroundColor: '#555'
  },
  white: {
    color: theme.palette.common.white
  },
  itemIcon: {
    minWidth: 50,
    marginLeft: 12
  }
}));

const TenantSidebar = () => {
  const classes = useStyles();
  const router = useRouter();
  const { data: assetDetails } = useSelector((state) => state.assets.assetDetails);
  const { data: tenantDetail } = useSelector((state) => state.tenants.tenantDetails);
  const { id } = router.query;
  const { tab } = router.match.params;
  const { state } = router.location;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <List>
        <ListItem
          className={classes.details}
          component={NavLink}
          to={{
            pathname: `/Asset/${assetDetails?.id}/${state?.from || 'Details'}`,
            state: { from: state?.from }
          }}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ArrowBackIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>{assetDetails?.name}</ListItemText>
        </ListItem>
        <Divider classes={{ root: classes.light }} />
        <ListItem
          className={classes.details}
          selected={tab === 'Details'}
          component={NavLink}
          to={`/Tenants/${id}/Details`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <DetailsIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Contact Details</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'LeasingStrategy'}
          component={NavLink}
          to={`/Tenants/${id}/LeasingStrategy`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <FolderSpecialIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Leasing Strategy</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'Options'}
          component={NavLink}
          to={`/Tenants/${id}/Options`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListAltIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Break Options</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'ContractClauses'}
          component={NavLink}
          to={`/Tenants/${id}/ContractClauses`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <DescriptionIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Contract Clauses</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'Reports'}
          component={NavLink}
          to={`/Tenants/${id}/Reports`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ShowChartIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Tenant Report</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default TenantSidebar;
