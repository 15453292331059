import React, { memo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { formatDate } from 'helpers/utils';
import PropTypes from 'prop-types';

import AlertItemActions from '../actions';
import useStyles from '../styles';
import Summary from '../summary';

const FacilityManagerAlert = ({ item, toggleDrawer }) => {
  const classes = useStyles();

  const { name, tenantname, assetname, portfolioname, date, id, entityid, assetid, type, ticket } =
    item;

  return (
    <Box display="flex" width="100%" flexDirection="column" key={id}>
      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <Divider className={classes.divider} />
      </Box>

      <Typography className={classes.accordionTitle}>{name}</Typography>
      {tenantname && <Summary title="Tenant" value={tenantname} />}

      <Summary title="Asset" value={assetname} />

      <Summary title="Portfolio" value={portfolioname} />

      <Summary title="Query" value={ticket.query} />

      <Summary title="Date Due" value={formatDate(ticket.datedue)} />

      <Summary title="Assigned to" value={ticket.assignedto} />

      <Box pt={1}>
        <Typography className={classes.accordionSummary}>{formatDate(date)}</Typography>
      </Box>
      <AlertItemActions
        URL={`/Asset/${assetid}/FacilityManager/${entityid}`}
        showMarkAsDoneBtn={false}
        toggleDrawer={toggleDrawer}
        entityId={entityid}
        type={type}
        name={name}
      />
    </Box>
  );
};

FacilityManagerAlert.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default memo(FacilityManagerAlert);
