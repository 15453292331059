/* eslint-disable react/require-default-props */
import React from 'react';

import { Box } from '@mui/material';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import NewButton from 'components/Buttons/NewButton';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import useStyles from 'styles/tables';

const Table = ({
  title = '',
  data = [],
  columns = [],
  loading = false,
  actions,
  components = {},
  handleNew,
  disabled,
  onRowClick,
  testid = 'table',
  customPagination,
  selection,
  search,
  large,
  shadow = true
}) => {
  const classes = useStyles();

  const cols = columns.map((col) => ({ ...col }));

  return (
    <Grow in data-testid={testid}>
      <Box className={clsx(large ? classes.containerLarge : classes.container)}>
        {title && (
          <Box className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>
            {handleNew && <NewButton onClick={handleNew} disabled={disabled} label="New" />}
          </Box>
        )}
        <Box className={clsx(shadow && classes.table)}>
          <MaterialTable
            disabled
            isLoading={loading}
            style={{
              padding: '20px 30px',
              borderRadius: 5,
              boxShadow: 'none',
              zIndex: 0,
              marginTop: 48,
              marginBottom: 48
            }}
            onRowClick={onRowClick}
            components={components}
            columns={cols}
            data={data}
            title={null}
            options={{
              pageSize: customPagination ? 20 : 10,
              pageSizeOptions: customPagination ? [5, 10] : [],
              selection,
              actionsColumnIndex: -1,
              sorting: true,
              search,
              headerStyle: {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 14
              },
              rowStyle: {
                fontFamily: 'Montserrat, sans-serif',
                padding: '0 12px'
              },
              cellStyle: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: 16,
                fontFamily: 'Open Sans, Regular',
                color: '#5C5C5C',
                maxWidth: 300
              }
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <Typography variant="subtitle1">
                    No records to display, Start Adding some with the New Button!
                  </Typography>
                )
              }
            }}
            actions={actions}
          />
        </Box>
      </Box>
    </Grow>
  );
};

export default Table;

Table.defaultProps = {
  selection: false,
  onRowClick: () => ({}),
  disabled: false,
  handleNew: null,
  customPagination: false,
  search: false,
  large: false
};

Table.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleNew: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  components: PropTypes.objectOf(PropTypes.any),
  selection: PropTypes.bool,
  testid: PropTypes.string,
  onRowClick: PropTypes.func,
  search: PropTypes.bool,
  customPagination: PropTypes.bool,
  large: PropTypes.bool,
  shadow: PropTypes.bool
};
