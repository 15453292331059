import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  headerText: {
    fontFamily: theme.fonts.baseSerif,
    fontSize: 30,
    color: theme.palette.common.black,
    fontWeight: 500
  },
  bigIndicator: {
    height: theme.spacing(0.5),
    background: theme.palette.primary.main
  },
  root: {
    flexGrow: 1,
    boxShadow: '0px 3px 14px #0000000F',
  },
  icon: {
    fontSize: 18,
    color: theme.palette.text.lightgrey
  },
  subtitle: {
    color: theme.palette.text.grey,
    paddingLeft: theme.spacing(1),
    fontFamily: theme.fonts.openSans
  },
  tabsRoot: {
    textTransform: 'unset !important',
    fontFamily: theme.fonts.baseSerif,
    fontWeight: 400,
    fontSize: theme.spacing(2),
    padding: theme.spacing(3)
  },
  textColorPrimary: {
    color: theme.palette.common.black
  },
  mapBtn: {
    textTransform: 'unset',
    textDecoration: 'none',
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main
  },
  tag: {
    color: theme.palette.text.lightgrey,
    marginLeft: theme.spacing(2),
    fontSize: 18
  },
  tabsContainer: {
    justifyContent: 'center'
  },
  tabPanel: {
    background: '#F2F3F7'
  },
  scroller: {
    flexGrow: '0'
  },
  edit: {
    color: '#7D7D7D',
    cursor: 'pointer',
    margin: theme.spacing(0, 2)
  },
  scrollable: {
    padding: '10px 20px',
    maxHeight: 'calc(100vh - 73px)',
    overflowY: 'scroll'
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexOne: {
    flex: 1
  },
  floatRight: {
    float: 'right'
  },
  spacing: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0, 2)
  },
  padding: {
    padding: theme.spacing(2, 4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  infoBox: {
    marginRight: theme.spacing(4),
    minWidth: 160
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  width: {
    minWidth: '80px'
  },
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(16),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    }
  }
}));

export default useStyles;
