import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  root: {
    flexGrow: 1,
    padding: '10px 20px'
  },
  subtitle: {
    color: theme.palette.text.grey,
    paddingLeft: theme.spacing(1),
    fontFamily: theme.fonts.openSans,
    fontSize: 14,
    marginLeft: theme.spacing(1.5)
  },
  tag: {
    color: theme.palette.text.lightgrey,
    marginLeft: theme.spacing(2),
    fontSize: 18
  },
  link: {
    fontSize: 22,
    marginLeft: theme.spacing(2)
  },
  scrollable: {
    padding: '10px 20px',
    maxHeight: 'calc(100vh - 73px)',
    overflowY: 'scroll'
  }
}));

export default useStyles;
