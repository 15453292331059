/* eslint-disable no-new */
import React, {Suspense, useEffect, useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import useLocalStorage from 'hooks/useLocalStorage';
import useUrlParams from 'hooks/useUrlParams';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions';

import CustomAutocomplete from './CustomAutocomplete';
import GraphCard from './GraphCard';
import InfoPanel from './InfoPanel';
import Map from './Map/Map';
import useStyles from './styles';
import Tasks from './Tasks';

const SkeletonComponent = (
    <Skeleton
        variant="rectangular"
        width="100%"
        animation="wave"
        height="100%"
        style={{borderRadius: '10px'}}
    />
);

const HomeScreen = () => {
    const classes = useStyles();
    const {
        homeData,
        loaded: homeDataLoaded,
        homeDataYears: yearOptions,
        yearFilter: year
    } = useSelector((state) => state.home);
    const {data: coordinates} = useSelector((state) => state.home.geoData);
    const {data, loaded: chartDataLoaded, loading} = useSelector((state) => state.home.chartData);
    const {portfolios} = useSelector((state) => state.portfolios);
    const {data: assets} = useSelector((state) => state.assets.assets);
    const {portfolioIds, setPortfolios, assetIds, setAssetIds} = useUrlParams();
    const {storedValue, setValue} = useLocalStorage('assetId', '');
    const [assetId, setAssetId] = useState(storedValue);
    const [period, setPeriod] = React.useState('month');

    const dispatch = useDispatch();

    const dataMap = [
        {
            title: "Income",
            charts: [
                {
                    name: 'Base Rent',
                    alias: 'base-rent',
                    link: '/BaseRent/Details',
                    data: data?.BaseRent
                },
                {
                    name: 'Service Charge',
                    alias: 'service-charge',
                    link: '/ServiceCharge/Details',
                    data: data?.ServiceCharge
                },
                {
                    name: 'Other Income',
                    alias: 'other-income',
                    link: '/OtherIncome/Details',
                    data: data?.OtherIncome
                },
                {
                    name: 'Cumulative Rent',
                    alias: 'cumulative-rent',
                    link: '/CumulativeRent/Details',
                    data: data?.CumulativeRent
                },
                {
                    name: 'Turnover Rent',
                    alias: 'turnover-rent',
                    link: '/TurnoverRent/Details',
                    data: data?.TurnoverRent
                }
            ]
        },
        {
            title: "Expenses",
            charts: [
                {
                    name: 'Opex',
                    alias: 'opex',
                    link: '/Opex/Details',
                    data: data?.Opex
                },
                {
                    name: 'Capex',
                    alias: 'capex',
                    link: '/Capex/Details',
                    data: data?.Capex
                },
                {
                    name: 'Leasing Fee',
                    alias: 'leasing-fee',
                    link: '/LeasingFee/Details',
                    data: data?.LeasingFee
                },
                {
                    name: 'Fit Out',
                    alias: 'fit-out',
                    link: '/FitOut/Details',
                    data: data?.FitOut
                },
                {
                    name: 'Other Expenses',
                    alias: 'other-expenses',
                    link: '/OtherExpenses/Details',
                    data: data?.OtherExpenses
                }
            ]
        },
        {
            title: "KPI's",
            charts: [
                {
                    name: 'NOI',
                    alias: 'noi',
                    link: '/NOI/Details',
                    data: data?.NOI
                }
            ]
        }
    ]

    const getCoordinates = useMemo(
        () =>
            coordinates
                ?.filter((obj) => assets.find((asset) => asset.id === obj.assetid))
                .map((obj, i) => ({
                    type: 'Feature',
                    properties: {
                        cluster: false,
                        id: i,
                        asset: assets.find((asset) => asset.id === obj.assetid)
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [parseFloat(obj.longitude), parseFloat(obj.latitude)]
                    }
                })),
        [coordinates]
    );

    const handleChange = (event, value) => {
        setPeriod(value);
    };

    useEffect(() => {
        dispatch(actions.getAssets(portfolioIds)).then((resp) => {
            setAssetId(resp[0].id);
            setValue(assetIds.length ? assetIds[0] : resp[0].id);
            const geoFilters = {
                PortfolioID: portfolioIds,
                AssetID: assetIds,
                Year: [year]
            };

            geoFilters.Year = [];

            dispatch(actions.getGeoData(geoFilters));
        });
        dispatch(actions.getChartData({
            PortfolioID: portfolioIds,
            AssetID: assetIds,
            Year: period === 'month' ? [year] : new Array(5).fill().map((_, i) => year + i)
        }, period, period === 'year' ? {year: year, length: 5} : null));
        dispatch(actions.getAlertsList(portfolioIds, assetIds));
    }, [portfolioIds, assetIds, year, period]);

    useEffect(() => {
        dispatch(actions.getFilterYears());
        dispatch(actions.getHomeData());
        dispatch(actions.getAlertsCount());
    }, []);

    useEffect(() => {
        const [firstId] = assetIds;

        if (firstId === storedValue || firstId === undefined) return;

        setValue(firstId);
    }, [assetIds]);

    const handleSelectYear = (e) => {
        dispatch(actions.setYearFilter(parseInt(e.target.value, 10)));
    };

    if (!homeDataLoaded && !chartDataLoaded) {
        return <Spinner/>;
    }

    return (
        <Box className={classes.paddingX}>
            <InfoPanel data={homeData}/>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={8}>
                    <Box className={classes.mapBox}>
                        <Map
                            coordinates={getCoordinates}
                            defaulCenter={[50.07, 14.43]}
                            defaultZoom={3}
                            mapType="hybrid"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Tasks/>
                </Grid>
            </Grid>
            <Box className={classes.filterWrapper}>
                <CustomAutocomplete
                    items={portfolios}
                    updateUrlParams={setPortfolios}
                    urlParams={portfolioIds}
                    label="Portfolios"
                    placeholder="Portfolios"
                    disabled={!!assetIds.length}
                />
                <CustomAutocomplete
                    items={assets}
                    updateUrlParams={setAssetIds}
                    urlParams={assetIds}
                    label="Assets"
                    placeholder="Assets"
                />
                <Select
                    options={yearOptions}
                    label="Year"
                    keyword="label"
                    value={year}
                    handleChange={handleSelectYear}
                    minWidth={400}
                    variant="outlined"
                    margin="0"
                />
                <ToggleButtonGroup
                    color="primary"
                    value={period}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="month">Monthly</ToggleButton>
                    <ToggleButton value="year">Annual</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {dataMap.map((item) => (
                <>
                    <Box className={classes.boxHeading}>
                        <Typography variant="h5">{item.title}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        {item.charts.map((item) => (
                            <Grid item xs={9} md={3}>
                                <Suspense fallback={SkeletonComponent}>
                                    <GraphCard
                                        dataCy={item.alias}
                                        to={`${item.link}/${storedValue || assetId}`}
                                        from={window.location.search}
                                        totalValue={item.data?.currentmonthrent}
                                        prevTotalValue={item.data?.lastmonthrent}
                                        series={[
                                            {
                                                name: `${item.name} Forecasts`,
                                                data: item.data?.forecastsList || []
                                            },
                                            {
                                                name: `${item.name} Actuals`,
                                                data: item.data?.actualforecastsList || []
                                            }
                                        ]}
                                        name={item.name}
                                        period={period}
                                        annualData={{year: year, length: 5}}
                                        loading={loading}
                                    />
                                </Suspense>
                            </Grid>
                        ))}
                    </Grid></>
            ))}
        </Box>
    );
};

export default HomeScreen;
