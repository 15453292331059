import {AntivirusNotificationServiceClient} from 'api/antivirus_notification_service_grpc_web_pb';
import {getEnviroment} from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const AntivirusService = new AntivirusNotificationServiceClient(getEnviroment(env));

enableDevTools([
    AntivirusService
]);