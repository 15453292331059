import React from 'react';

import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  statsTitle: {
    color: '#5C5C5C',
    fontFamily: theme.fonts.openSans,
    cursor: 'pointer'
  },
  editIcon: {
    color: '#7D7D7D',
    cursor: 'pointer',
    margin: theme.spacing(0, 2)
  }
}));

const CostManagerItem = ({ name, url, handleDelete }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box mt={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.statsTitle} onClick={() => router.push(url)}>
          {name}
        </Typography>

        <Box display="flex" alignItems="center">
          <EditIcon onClick={() => router.push(url)} className={classes.editIcon} />
          <DeleteIcon onClick={handleDelete} className={classes.editIcon} />
        </Box>
      </Box>
      <Box mt={3}>
        <Divider />
      </Box>
    </Box>
  );
};

export default CostManagerItem;

CostManagerItem.defaultProps = {
  handleDelete: () => {}
};

CostManagerItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleDelete: PropTypes.func
};
