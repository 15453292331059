import {GeoServicePromiseClient} from 'api/geo_grpc_web_pb';
import {getEnviroment} from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const GeolocationService = new GeoServicePromiseClient(ENV);

enableDevTools([
    GeolocationService
]);