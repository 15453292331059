import React from 'react';

import { Box, RadioGroup, Typography, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {},
  option: {
    backgroundColor: theme.palette.background.grey,
    minWidth: 250,
    borderRadius: 5,
    height: 50,
    marginBottom: theme.spacing(1),
    border: '1px solid #D9D9D9',
    marginLeft: 0
  },
  label: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },

  subtitle: {
    margin: theme.spacing(1, 0),
    fontSize: 12,
    fontFamily: theme.fonts.openSans,
    color: '#7D7D7D'
  }
}));
const RadioButtonGroup = ({ value, handleChange, label, children }) => {
  const classes = useStyles();

  return (
    (<FormControl variant="standard" component="fieldset" className={classes.root}>
      <Typography className={classes.subtitle}>{label}</Typography>
      <RadioGroup aria-label="Type" name="type" value={value} onChange={handleChange}>
        <Box display="flex" flexWrap="wrap">
          {children}
        </Box>
      </RadioGroup>
    </FormControl>)
  );
};

export default RadioButtonGroup;

RadioButtonGroup.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
};
