import React from 'react';

import { Dialog, Box, DialogContent, DialogActions, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';
import useStyles from 'styles/forms';

import Upload from '../Upload';

const UploadModal = ({
  open,
  handleClose,
  acceptedFiles,
  setAcceptedFiles,
  handleSave,
  loading
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-upload"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.assetTitle}>Attach Files</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <Box>
          <Upload setAcceptedFiles={setAcceptedFiles} />
          {acceptedFiles.length > 0 &&
            acceptedFiles.map((item) => (
              <Box key={item.path} display="flex" alignItems="center">
                <Typography className={classes.uploadText} style={{ marginLeft: 10 }}>
                  {item.name}
                </Typography>
              </Box>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleClose} />
          <SaveButton onClick={handleSave} loading={loading} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;

UploadModal.defaultProps = {
  loading: false
};

UploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setAcceptedFiles: PropTypes.func.isRequired,
  acceptedFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
