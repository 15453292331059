import React, {Suspense, useEffect, useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApexChart from 'components/Charts';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import {formatNumberToCurrency} from 'helpers/utils';
import useLocalStorage from 'hooks/useLocalStorage';
import useRouter from 'hooks/useRouter';
import useUrlParams from 'hooks/useUrlParams';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useRouteMatch} from 'react-router-dom';
import * as actions from 'store/actions';
import {config} from 'views/HomeScreen/chartConfig';

import useStyles from './styles';
import TableActualsForecasts from './TableActualsForecasts';
import TableSummary from './TableSummary';

const namesMapper = {
    BaseRent: 'Base Rent',
    TurnoverRent: 'Turnover Rent',
    ServiceCharge: 'Service Charge',
    OtherIncome: 'Other Income',
    CumulativeRent: 'Cumulative Rent',
    NOI: 'NOI',
    Opex: 'Opex',
    Capex: 'Capex',
    OtherExpenses: 'Other Expenses'
};

const ChartDetails = () => {
    const yearOptions = useSelector((state) => state.home.homeDataYears);
    const year = useSelector((state) => state.home.yearFilter);
    const {data, error, loaded} = useSelector((state) => state.home.chartDataPage);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {portfolioIds} = useUrlParams();
    const {data: assets} = useSelector((state) => state.assets.assets);
    const {
        data: summaryData,
        loading: summaryDataLoading,
        loaded: summaryDataLoaded
    } = useSelector((state) => state.home.summaryData);
    const {data: forecastsData} = useSelector((state) => state.home.forecasts);
    const {data: actualsData} = useSelector((state) => state.home.actuals);
    const {storedValue, setValue} = useLocalStorage('assetId', '');
    const {
        params: {tab, assetId: id}
    } = useRouteMatch();
    const [assetId, setAssetId] = useState(id || storedValue || assets[0]?.id);
    const router = useRouter();
    const queryParam = new URLSearchParams(router.location.search);
    const [fromValue, setFromValue] = useState('');
    const [locationSearch, setLocationSearch] = useState('');

    useEffect(() => {
        if (queryParam.get('from')) {
            setFromValue(queryParam.get('from'));
        }
    }, [fromValue]);

    useEffect(() => {
        if (router.location.search) {
            setLocationSearch(router.location.search);
        }
    }, [locationSearch]);

    const incomeItems = useMemo(
        () => [
            {id: 1, to: `/BaseRent/Details/${assetId}`, label: 'Base Rent', active: 'BaseRent'},
            {
                id: 2,
                to: `/TurnoverRent/Details/${assetId}`,
                label: 'Turnover Rent',
                active: 'TurnoverRent'
            },
            {
                id: 3,
                to: `/ServiceCharge/Details/${assetId}`,
                label: 'Service Charge',
                active: 'ServiceCharge'
            },
            {
                id: 4,
                to: `/OtherIncome/Details/${assetId}`,
                label: 'Other Income',
                active: 'OtherIncome'
            },
            {
                id: 5,
                to: `/CumulativeRent/Details/${assetId}`,
                label: 'Cumulative Rent',
                active: 'CumulativeRent'
            },
            {
                id: 6,
                to: `/NOI/Details/${assetId}`,
                label: 'Net Operating Income',
                active: 'NOI'
            }
        ],
        [assetId]
    );

    const expenseItems = useMemo(
        () => [
            {id: 1, to: `/Opex/Details/${assetId}`, label: 'Opex', active: 'Opex'},
            {id: 2, to: `/Capex/Details/${assetId}`, label: 'Capex', active: 'Capex'},
            {
                id: 3,
                to: `/LeasingFee/Details/${assetId}`,
                label: 'Leasing Fee',
                active: 'LeasingFee'
            },
            {
                id: 4,
                to: `/FitOut/Details/${assetId}`,
                label: 'Fit Out',
                active: 'FitOut'
            },
            {
                id: 5,
                to: `/OtherExpenses/Details/${assetId}`,
                label: 'Other Expenses',
                active: 'OtherExpenses'
            }
        ],
        [assetId]
    );

    useEffect(() => {
        if (!data || !id || id === assetId) return;

        setValue(id);
    }, [assetId]);

    useEffect(() => {
        dispatch(actions.getFilterYears());
        dispatch(
            actions.getChartDataPerPage(tab, {PortfolioID: [], AssetID: [assetId], Year: [year]})
        );
        dispatch(
            actions.getSummaryTableData(tab, {PortfolioID: [], AssetID: [assetId], Year: [year]})
        );
        dispatch(actions.getTenantsActuals(tab, {PortfolioID: [], AssetID: [assetId], Year: [year]}));
        dispatch(
            actions.getTenantsForecasts(tab, {PortfolioID: [], AssetID: [assetId], Year: [year]})
        );
    }, [assetId, tab, year]);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        dispatch(actions.getAssets(portfolioIds));
    }, []);

    const handleSelectAsset = (e) => {
        setAssetId(e.target.value);
        setValue(e.target.value);
        const search = queryParam.get('from') ? `?from=${queryParam.get('from')}` : '';
        router.push(`/${tab}/Details/${e.target.value}${search}`);
    };

    const handleSelectYear = (e) => {
        dispatch(actions.setYearFilter(e.target.value));
    };

    if (error && loaded) {
        return (
            <Typography color="error" style={{marginLeft: 30, marginTop: 30}}>
                {error}
            </Typography>
        );
    }

    return (
        <Box className={classes.wrapper}>
            <Grid container spacing={5} wrap="wrap">
                <Grid item xs={12} sm={2} md={2}>
                    <Box className={classes.boxLeft}>
                        <Paper elevation={3} className={classes.paper}>
                            <Box className={classes.sideBox}>
                                <ListItem component={NavLink} to={`${fromValue || `/${locationSearch}`}`} button>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <ArrowBackIcon/>
                                    </ListItemIcon>
                                    <ListItemText>Home</ListItemText>
                                </ListItem>
                                <Typography variant="h6">Income</Typography>
                                <List>
                                    {incomeItems.map((item) => (
                                        <ListItem
                                            key={item.id}
                                            component={NavLink}
                                            selected={tab === item.active}
                                            to={item.to}
                                            button
                                            divider
                                        >
                                            <ListItemText>{item.label}</ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                            <Box className={classes.sideBox}>
                                <Typography variant="h6">Expenses</Typography>
                                <List>
                                    {expenseItems.map((item) => (
                                        <ListItem
                                            key={item.id}
                                            component={NavLink}
                                            selected={tab === item.active}
                                            to={item.to}
                                            button
                                            divider
                                        >
                                            <ListItemText>{item.label}</ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                    <Select
                        options={assets}
                        label="Assets"
                        keyword="name"
                        value={assetId}
                        handleChange={handleSelectAsset}
                    />
                    <Select
                        options={yearOptions}
                        label="Year"
                        keyword="label"
                        value={year.toString()}
                        handleChange={handleSelectYear}
                    />
                    <Paper>
                        <Box style={{padding: '20px'}}>
                            <Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h4">{namesMapper[tab]}</Typography>
                                </Box>

                                <Box className={classes.wrapCard}>
                                    <Typography variant="h6">
                                        {`Actuals ${year}: ${formatNumberToCurrency(
                                            data?.actualforecastsList?.reduce((acc, cur) => acc + cur, 0)
                                        )}`}
                                    </Typography>
                                </Box>
                                <Box className={classes.wrapCard}>
                                    <Typography variant="h6">
                                        {`Total Forecasted ${year}: ${formatNumberToCurrency(
                                            data?.forecastsList?.reduce((acc, cur) => acc + cur, 0)
                                        )}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Suspense fallback={<Spinner mini/>}>
                                    <ApexChart
                                        height={350}
                                        options={config.options}
                                        series={[
                                            {
                                                name: `${namesMapper[tab]} Forecasts`,
                                                data: summaryData?.totalforecastsList || []
                                            },
                                            {
                                                name: `${namesMapper[tab]} Actuals`,
                                                data: data?.actualforecastsList || []
                                            }
                                        ]}
                                    />
                                </Suspense>
                            </Box>
                        </Box>
                        {summaryData && (
                            <Box className={classes.summaryContainer}>
                                <TableSummary
                                    data={summaryData || null}
                                    loading={summaryDataLoading}
                                    loaded={summaryDataLoaded}
                                    tab={tab}
                                />
                            </Box>
                        )}
                    </Paper>
                    <Paper elevation={3} className={classes.margin} style={{overflowX: 'scroll'}}>
                        <Box>
                            <Typography className={classes.padding}>Forecasts</Typography>
                        </Box>
                        <Box>
                            <TableActualsForecasts
                                data={forecastsData || []}
                                name="forecastsList"
                                tab={tab}
                                assetId={assetId}
                            />
                        </Box>
                    </Paper>
                    <Paper elevation={3} className={classes.margin} style={{overflowX: 'scroll'}}>
                        <Box>
                            <Typography className={classes.padding}>Actuals</Typography>
                        </Box>
                        <Box>
                            <TableActualsForecasts
                                data={actualsData || []}
                                name="actualsList"
                                tab={tab}
                                assetId={assetId}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};
export default ChartDetails;
