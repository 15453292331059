import React from 'react';

import { Box, TextField, Radio, FormControlLabel, InputAdornment } from '@mui/material';
import RadioButtonGroup from 'components/Buttons/RadioButtonGroup';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Filters/Dropdown';
import { formatForBackEnd } from 'helpers/utils';
import PropTypes from 'prop-types';
import useStyles from 'styles/forms';

import Schedule from '../Schedule';

const Supplier = ({ form, setForm }) => {
  const classes = useStyles();

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      supplier: {
        ...prevState.supplier,
        [name]: value
      }
    }));
  };

  const handleChangePayment = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      supplier: {
        ...prevState.supplier,
        [name]: {
          ...prevState.supplier[name],
          paymentAmount: +value
        }
      }
    }));
  };

  const handleDateChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,

      [name]: formatForBackEnd(value)
    }));
  };

  return (
    (<Box>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" pt={3} pb={2}>
        <DatePicker
          selectedDate={form?.startdate ? new Date(form.startdate) : new Date()}
          setSelectedDate={(e) => handleDateChange('startdate', e)}
          label="Start Date"
        />
        <DatePicker
          selectedDate={form?.enddate ? new Date(form.enddate) : new Date()}
          setSelectedDate={(e) => handleDateChange('enddate', e)}
          label="End Date"
          minDate={form.startdate}
        />
      </Box>
      <RadioButtonGroup
        value={form?.supplier?.paymentTerms}
        handleChange={(e) => handleChange('paymentTerms', e.target.value)}
        label="Payment Terms"
      >
        <FormControlLabel
          value="RegularPayment"
          control={<Radio color="primary" />}
          label="Regular Payment"
          className={classes.optionXl}
          classes={{ label: classes.label }}
        />
        <FormControlLabel
          value="ScheduledPayment"
          control={<Radio color="primary" />}
          label="Schedule Payment"
          className={classes.optionXl}
          classes={{ label: classes.label }}
        />
      </RadioButtonGroup>
      <Box display="flex" justifyContent="space-between" pt={2}>
        {form?.supplier?.paymentTerms === 'RegularPayment' && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            className={classes.box}
          >
            <TextField
              label="Payment Amount"
              variant="outlined"
              className={classes.textField}
              value={form?.supplier?.regularPayment?.paymentAmount}
              type="number"
              style={{ width: '48%' }}
              onChange={(e) => handleChangePayment('regularPayment', e.target.value)}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  classes: {
                    root: classes.input
                  }
                }
              }}
            />
            <Dropdown
              items={[{ name: 'Monthly' }, { name: 'Quarterly' }, { name: 'Yearly' }]}
              value={form?.supplier.frequency}
              handleChange={(e) => handleChange('frequency', e.target.value)}
              label="Bill"
              keyword="name"
            />
          </Box>
        )}
      </Box>
      {form?.supplier?.paymentTerms === 'ScheduledPayment' && (
        <Schedule form={form.supplier} setForm={setForm} type="supplier" />
      )}
    </Box>)
  );
};

export default Supplier;

Supplier.propTypes = {
  setForm: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired
};
