import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'cneter',
    position: 'absolute',
    top: '50%'
  },
  mini: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'cneter',
    marginTop: 50
  }
}));

const Spinner = ({ mini }) => {
  const classes = useStyles();
  return (
    <div className={mini ? classes.mini : classes.loadingContainer} data-testid="spinner">
      <CircularProgress color="secondary" />
    </div>
  );
};

export default React.memo(Spinner);

Spinner.defaultProps = {
  mini: false
};

Spinner.propTypes = {
  mini: PropTypes.bool
};
