import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  select: {
    color: '#5C5C5C'
  },
  label: {
    fontFamily: theme.fonts.openSans
  },
  paper: {
    boxShadow: '0px 3px 14px #0000002B',
    maxHeight: 350,
    maxWidth: 300
  }
}));
export default useStyles;
