import React from 'react';

import Box from '@mui/material/Box';
import { Euro } from '@mui/icons-material';
import StatsIcon from 'components/Icons/StatsIcon';
import { numberWithCommas } from 'helpers/utils';
import { useSelector } from 'react-redux';

const TenantPerformanceKpis = () => {
  const data = useSelector((state) => state.reports.tenantPerformance.tenantPerformance);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
      <StatsIcon
        title="Base Rent"
        statsValue={`€${numberWithCommas(data.baserent, '0')}`}
        Icon={Euro}
      />
      <StatsIcon
        title="Turnover Rent"
        statsValue={`€${numberWithCommas(data.turnoverrent, '0')}`}
        Icon={Euro}
      />
      <StatsIcon
        title="Service Charge"
        statsValue={`€${numberWithCommas(data.servicecharge, '0')}`}
        Icon={Euro}
      />
      <StatsIcon
        title="Turnover"
        statsValue={`€${numberWithCommas(data.turnover, '0')}`}
        Icon={Euro}
      />
      <StatsIcon title="Effort Ratio" statsValue={`${data.effortratio}%`} />
      <StatsIcon
        title="Sales Density"
        statsValue={`€${numberWithCommas(data.salesdensity, '0')}`}
        Icon={Euro}
      />
    </Box>
  );
};

export default React.memo(TenantPerformanceKpis);
