import React, { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import AlertItems from '../AlertItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.grey,
    padding: theme.spacing(4)
  },
  alertTitle: {
    fontFamily: theme.fonts.base,
    color: '#000000',
    textTransform: 'uppercase',
    fontWeight: 500,
    paddingBottom: theme.spacing(1)
  }
}));

// alert types
const ALERT_TYPE_TENANT_CONTRACT_EXPIRATION = 1;
const ALERT_TYPE_VACANT_UNITS = 3;
const ALERT_TYPE_TENANT_CONTRACT_BREAK = 4;
const ALERT_TYPE_OPEX_CONTRACT_EXPIRATION = 5;

// category types
const ALERT_CATEGORY_ASSET_MANAGEMENT = 1;
const ALERT_CATEGORY_PROPERTY_MANAGEMENT = 2;

const SimpleAccordion = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.alerts.loading);
  const { alerts } = useSelector((state) => state.alerts);
  const { asset, portfolio } = useSelector((state) => state.alerts.filters);
  const assetAlerts = useMemo(
    () => alerts.filter((alert) => alert.category === ALERT_CATEGORY_ASSET_MANAGEMENT),
    [alerts]
  );
  const propertyAlerts = useMemo(
    () => alerts.filter((alert) => alert.category === ALERT_CATEGORY_PROPERTY_MANAGEMENT),
    [alerts]
  );

  const getFilteredData = useCallback(
    (data, type) => data.filter((alert) => alert.type === type),

    [assetAlerts, propertyAlerts]
  );

  if (loading) {
    return (
      <Box pt={10}>
        <Spinner mini />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.alertTitle}>Asset management</Typography>
      <AlertItems
        data={[
          {
            name: 'Upcoming Lease Expiry',
            label: 'lease_expiry',
            alertcount: getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_EXPIRATION).length,
            items: getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_EXPIRATION)
          },
          {
            name: 'Upcoming Lease Break',
            label: 'lease_break',
            alertcount: getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_BREAK).length,
            items: getFilteredData(assetAlerts, ALERT_TYPE_TENANT_CONTRACT_BREAK)
          },
          {
            name: 'Vacant Units',
            label: 'vacant_units',
            alertcount: getFilteredData(assetAlerts, ALERT_TYPE_VACANT_UNITS).length,
            items: getFilteredData(assetAlerts, ALERT_TYPE_VACANT_UNITS)
          },
          {
            name: 'Opex Contract Expiries',
            label: 'contract_expiry',
            alertcount: getFilteredData(propertyAlerts, ALERT_TYPE_OPEX_CONTRACT_EXPIRATION).length,
            items: getFilteredData(propertyAlerts, ALERT_TYPE_OPEX_CONTRACT_EXPIRATION)
          }
        ]}
        asset={asset}
        portfolio={portfolio}
        toggleDrawer={() => dispatch(actions.toggleAlertMenu())}
      />
    </div>
  );
};

export default SimpleAccordion;
