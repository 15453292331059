import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { formatDate, stableSort, getComparator } from 'helpers/utils';
import PropTypes from 'prop-types';

import TableHeader from '../TableHeader';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Invoice', sortable: true },
  {
    id: 'dateSent',
    numeric: true,
    disablePadding: false,
    label: 'Date Sent',
    sortable: true
  },
  { id: 'type', numeric: true, disablePadding: false, label: 'Invoice Type' },
  { id: 'company', numeric: true, disablePadding: false, label: 'Company' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Total Amount', sortable: true },
  {
    id: 'remainingAmount',
    numeric: true,
    disablePadding: false,
    label: 'Remaining Amount',
    sortable: true
  }
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: 500,
    overflowY: 'auto'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cell: {
    fontFamily: theme.fonts.openSans,
    fontSize: 16,
    color: '#5C5C5C',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 76,
    overflow: 'hidden',
    width: 120,
    paddingLeft: 33
  },
  celbase: {
    fontFamily: theme.fonts.openSans,
    fontSize: 16,
    color: '#5C5C5C',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 76,
    overflow: 'hidden',
    width: 120
  },
  header: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
    color: '#000000'
  }
}));

const InoviceOwedList = ({ invoices }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="invoices awaiting"
          size="medium"
          aria-label="invoices awaiting table"
        >
          <TableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(invoices, getComparator(order, orderBy)).map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow tabIndex={-1} key={row.name}>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    className={classes.celbase}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {formatDate(row.dateSent)}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {row.type}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {row.company}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {`€${row.amount}`}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {`€${row.remainingAmount}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InoviceOwedList;

InoviceOwedList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.any).isRequired
};
