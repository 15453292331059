import React, { memo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { formatDate } from 'helpers/utils';
import PropTypes from 'prop-types';

import AlertItemActions from '../actions';
import useStyles from '../styles';
import Summary from '../summary';

const VacantUnitsAlert = ({ item, toggleDrawer }) => {
  const classes = useStyles();
  const { name, assetname, portfolioname, date, id, entityid, type, vacantunits } = item;

  return (
    <Box display="flex" width="100%" flexDirection="column" key={id}>
      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <Divider className={classes.divider} />
      </Box>
      <Typography className={classes.accordionTitle}>{name}</Typography>

      <Summary title="Portfolio" value={portfolioname} />

      <Summary title="Asset" value={assetname} />

      <Summary title="Unit" value={vacantunits.name} />

      <Summary title="GLA" value={vacantunits.gla} />

      <Box pt={1}>
        <Typography className={classes.accordionSummary}>{formatDate(date)}</Typography>
      </Box>

      <AlertItemActions
        URL=""
        showFixBtn={false}
        toggleDrawer={toggleDrawer}
        entityId={entityid}
        type={type}
        name={name}
      />
    </Box>
  );
};

VacantUnitsAlert.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default memo(VacantUnitsAlert);
