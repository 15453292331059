import React from 'react';

import Box from '@mui/material/Box';
import Breadcrumps from 'components/Breadcrumps';
import TableauDashboard from 'components/TableauDashboard';
import useRouter from 'hooks/useRouter';

const AdvancedReporting = () => {
  const router = useRouter();

  return (
    <>
      <Box component="div" marginBottom={2}>
        <Breadcrumps parent={router.location?.state?.from} />
      </Box>
      <TableauDashboard />
    </>
  );
};

export default AdvancedReporting;
