import React from 'react';

import Container from '@mui/material/Container';
import { node, string } from 'prop-types';

export default function ContainerComponent({ size, children, ...props }) {
  return (
    <Container maxWidth={size} {...props}>
      {children}
    </Container>
  );
}

ContainerComponent.defaultProps = {
  size: 'xl'
};

ContainerComponent.propTypes = {
  size: string,
  children: node.isRequired
};
