import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumps from 'components/Breadcrumps';
import Spinner from 'components/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import OrganisationsTable from './OrganisationsTable';

const columns = [
  { title: 'Domain', field: 'domain' },
  {
    title: 'Name',
    field: 'name'
  }
];

const Organisations = () => {
  const organisations = useSelector((state) => state.portfolios.organisations.organisations);
  const loading = useSelector((state) => state.portfolios.organisations.loading);
  const loaded = useSelector((state) => state.portfolios.organisations.loaded);
  const error = useSelector((state) => state.portfolios.organisations.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getOrganisations());
  }, []);

  if (loading || !loaded) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box m={5} p={5}>
        <Typography color="error" variant="h5">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Breadcrumps />
      <OrganisationsTable
        title={`Organisations (${organisations.length})`}
        columns={columns}
        data={organisations}
      />
    </>
  );
};

export default Organisations;
