import React, { useEffect, lazy, Suspense } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NewButton from 'components/Buttons/NewButton';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

const FacilityTable = lazy(() => import('Workflows/Shared/FacilityManager/FacilityTable'));
const bundleId = 'Page-1-org';

const TenantManager = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();
  const { loaded, loading, tenantManager, error } = useSelector((state) => state.tenantManager);
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);
  const crumps = useSelector((state) => state.tenants.crumps.crumps);

  useEffect(() => {
    if (!query.id) {
      return;
    }

    dispatch(actions.getTenantCrumps(query.id));
    dispatch(actions.getTenantManager(query.id));
  }, [query.id]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId)) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);

  function deleteFacilityTicket(ticketId) {
    dispatch(actions.deleteTenantManagerTicket(ticketId)).then(() => {
      dispatch(actions.getTenantManager(query.id));
    });
  }

  async function handleSave(form) {
    await dispatch(actions.createTenantManager(crumps.assetid, query.id, form))
      .then(() => {
        dispatch(actions.getTenantManager(query.id));
        dispatch(closeModal());
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const handleNew = () => {
    dispatch(
      openModal({
        modalType: 'FacilityManagerModal',
        modalProps: {
          facilityManager: {},
          handleSave,
          header: 'Tenant Manager'
        }
      })
    );
  };

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3} m={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography className={classes.header}>Tenant Manager</Typography>
        <NewButton onClick={handleNew} label="New" />
      </Box>
      <Suspense fallback={<Spinner />}>
        {tenantManager && (
          <FacilityTable
            data={tenantManager}
            loading={loading}
            link={`/Tenants/${query.id}/TenantManager`}
            handleDelete={(ticketId) => deleteFacilityTicket(ticketId)}
          />
        )}
      </Suspense>
    </Box>
  );
};

export default TenantManager;
