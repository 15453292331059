/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Typography, Divider } from '@mui/material';

import useStyles from '../../styles';

const SupplierInfo = ({ details }) => {
  const classes = useStyles();
  const officeAdress = `${details.street} ${details.city} ${details.postcode} ${details.country}`;

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Divider className={classes.divider} />
      </Box>
      <Box className={classes.boxContainer}>
        <Box display="flex" flexDirection="column" className={classes.boxInner}>
          <Typography className={classes.statsTitle}>Company Name</Typography>
          <Typography className={classes.statsValue}>{details.companyname || 'N/A'}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.boxInner}>
          <Typography className={classes.statsTitle}>Office Address</Typography>
          <Typography className={classes.statsValue}>{officeAdress || 'N/A'}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.boxInner}>
          <Typography className={classes.statsTitle}>Point of Contact</Typography>
          <Typography className={classes.statsValue}>{details.contact || 'N/A'}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.boxInner}>
          <Typography className={classes.statsTitle}>Email</Typography>
          <Typography className={classes.statsValue}>{details.email || 'N/A'}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.boxInner}>
          <Typography className={classes.statsTitle}>Telephone</Typography>
          <Typography className={classes.statsValue}>{details.phone || 'N/A'}</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default React.memo(SupplierInfo);
