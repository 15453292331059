import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ConditionalLink = ({ children, to, from, condition }) =>
  !!condition && to ? (
    <Link
      style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
      to={{
        pathname: to,
        state: { from }
      }}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export default ConditionalLink;

ConditionalLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  condition: PropTypes.bool.isRequired
};
