/* eslint-disable no-console */
import React, { useEffect, lazy, Suspense, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Crumps from 'components/Breadcrumps/Crumps';
import NewButton from 'components/Buttons/NewButton';
import Container from 'components/Container';
import { openModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

const LegalTable = lazy(() => import('../../Shared/LegalTracker/LegalTable'));
const bundleId = 'Page-1-org';
const bundleLegalId = 'ALL-ALL-ALL';

const LegalTracker = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();
  const { loaded, legalTracker, loading, allIds } = useSelector((state) => state.legalTracker);
  const [processing, setProcessing] = useState(false);
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);
  const crumps = useSelector((state) => state.tenants.crumps.crumps);

  useEffect(() => {
    if (crumps.tenantid === query.id && loaded) {
      return;
    }
    setProcessing(true);

    if (query.id) {
      dispatch(actions.getTenantCrumps(query.id));

      dispatch(actions.getLegalTracker(query.id, bundleLegalId)).finally(() => {
        setProcessing(false);
      });
    }
  }, [query]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId)) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);

  async function handleSave(form) {
    const result = await console.log(form);

    return result;
  }

  const handleNew = useCallback(() => {
    dispatch(
      openModal({
        modalType: 'LegalTrackerModal',
        modalProps: {
          legalTracker: {},
          handleSave
        }
      })
    );
  }, []);

  if (!loaded || processing) {
    return <Spinner />;
  }

  return (
    <Container pb={2}>
      <Crumps
        fragments={[
          { title: crumps.assetname, href: `/Asset/${crumps.assetid}` },
          { title: crumps.tenantname, href: `/Tenants/${crumps.tenantid}` },
          { title: 'Legal Tracker', href: `/Tenants/${crumps.tenantid}/LegalTracker` }
        ]}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between" m={3} p={2}>
        <Typography className={classes.header}>Legal Tracker</Typography>
        <NewButton onClick={handleNew} label="New" />
      </Box>

      <Suspense fallback={<Spinner />}>
        <LegalTable
          data={legalTracker}
          link={`/Tenants/${query.id}/LegalTracker`}
          loading={loading}
          allIds={allIds}
          setLegal={(bundle) => dispatch(actions.setLegalTrackerBundle(bundle))}
          fetchLegal={(id, bundle) => dispatch(actions.getLegalTracker(id, bundle))}
        />
      </Suspense>
    </Container>
  );
};

export default LegalTracker;
