/* eslint-disable react/prop-types */
import React from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const BoxWrap = ({ children }) => {
  const loading = useSelector((state) => state.reports.turnover.loading);

  return (
    <Box
      p={3}
      mb={3}
      width="95%"
      overflow="auto"
      style={loading ? { pointerEvents: 'none', opacity: 0.4 } : {}}
    >
      {children}
    </Box>
  );
};

export default React.memo(BoxWrap);
