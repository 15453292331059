import React from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  remove: {
    fontFamily: theme.fonts.base,
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    margin: theme.spacing(0, 1)
  },
  fix: {
    fontFamily: theme.fonts.base,
    background: theme.palette.warning.main,
    color: theme.palette.common.white,
    margin: theme.spacing(0, 1),
    minWidth: 100
  }
}));

export default function AlertActions({
  toggleDrawer,
  URL,
  entityId,
  type,
  name,
  showMarkAsDoneBtn = true,
  showFixBtn = true
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();

  const navigateAlert = (alertname) => {
    toggleDrawer();
    if (alertname !== 'Supply') {
      router.push(URL);
    }
  };

  const handleMarkDone = () => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Remove Alert',
          content:
            'Are you sure you want to remove the selected Alert? This action cannot be undone!',
          handleConfirm: () => {
            dispatch(actions.hideAlert(entityId, type));
            dispatch(closeModal());
          }
        }
      })
    );
  };

  return (
    <Box display="flex" justifyContent="space-between" mt={2}>
      {showFixBtn && (
        <Button
          className={classes.fix}
          onClick={() => navigateAlert(name)}
          variant="contained"
          color="primary"
        >
          Fix
        </Button>
      )}
      {showMarkAsDoneBtn && (
        <Button
          variant="contained"
          color="primary"
          className={classes.remove}
          onClick={handleMarkDone}
        >
          Mark as Done
        </Button>
      )}
    </Box>
  );
}

AlertActions.defaultProps = {
  showMarkAsDoneBtn: true,
  showFixBtn: true
};

AlertActions.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  URL: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  entityId: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  showMarkAsDoneBtn: PropTypes.bool,
  showFixBtn: PropTypes.bool
};
