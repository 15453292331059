import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import AlertBox from './AlertBox';

const useStyles = makeStyles((theme) => ({
  alertWrap: {
    minHeight: 65
  },
  heading: {
    fontSize: 16,
    margin: theme.spacing(0, 1),
    fontFamily: theme.fonts.openSans
  },
  accordion: {
    marginTop: theme.spacing(1.2),
    boxShadow: '0px 3px 6px #00000029',
    border: 'none'
  },
  accordionBody: {
    position: 'static'
  },
  icon: {
    color: '#AAAAAA'
  },
  accordionDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  }
}));

const AlertItem = ({ data, asset, portfolio, toggleDrawer }) => {
  const classes = useStyles();
  const { taskToShow } = useSelector((state) => state.alerts);
  const [panel, setPanel] = useState('');
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const getFilteredItems = (items) => {
    let filteredItems = [...items];

    if (asset) {
      filteredItems = filteredItems.filter((itm) => itm.assetid === asset);
    }
    if (portfolio) {
      filteredItems = filteredItems.filter((itm) => itm.portfolioid === portfolio);
    }
    return filteredItems;
  };

  const handleChange = (panelName) => (_, isExpanded) => {
    setPanel(panelName);
    setExpanded(isExpanded);

    if (taskToShow !== null) {
      dispatch(actions.showTask(null));
    }
  };

  return (
    <>
      {data.map((alert) => (
        <Accordion
          className={classes.accordion}
          key={alert.name}
          classes={{ root: classes.accordionBody }}
          expanded={taskToShow === alert.label || (expanded && alert.label === panel)}
          onChange={handleChange(alert.label)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.alertWrap}
            disabled={!getFilteredItems(alert.items).length > 0}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography className={classes.heading}>{alert.name}</Typography>
              <Badge
                badgeContent={
                  getFilteredItems(alert.items).length > 0
                    ? getFilteredItems(alert.items).length
                    : null
                }
                color="error"
                showZero
              >
                <NotificationsIcon className={classes.icon} />
              </Badge>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.accordionDetails}>
              {getFilteredItems(alert.items).map((item) => (
                <AlertBox
                  item={item}
                  toggleDrawer={toggleDrawer}
                  key={`${item.entityid}-${item.type}`}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default AlertItem;

AlertItem.defaultProps = {
  asset: '',
  portfolio: ''
};

AlertItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  asset: PropTypes.string,
  portfolio: PropTypes.string,
  toggleDrawer: PropTypes.func.isRequired
};
