import {ActualServiceClient} from 'api/actual_grpc_web_pb';
import {getEnviroment} from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const DataActualsService = new ActualServiceClient(getEnviroment(env));

enableDevTools([
    DataActualsService
]);