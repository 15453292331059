import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

import useStyles from '../styles';

const DropDown = ({ value, handleChange, label, items, disabled, keyword, small, dataCy }) => {
  const classes = useStyles();

  return (
    (<FormControl
      variant="outlined"
      className={classes.formControl}
      style={small ? { width: 200 } : { width: 400 }}
      size={small ? 'small' : 'medium'}
    >
      <InputLabel id={`${label}-input-label`} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        variant="standard"
        labelId={`${label}-select-id-label`}
        id={`${label}-select-id`}
        value={value}
        onChange={handleChange}
        label={label}
        data-cy={dataCy}
        classes={{ select: classes.label }}
        className={classes.select}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          getContentAnchorEl: null,
          classes: {
            paper: classes.paper
          }
        }}>
        {items.map((item) => {
          if (typeof item === 'object') {
            return (
              <MenuItem
                key={item.id || item[keyword]}
                value={item.id || item[keyword]}
                disabled={value === (item.id || item[keyword])}
                className={classes.label}
              >
                {item[keyword]}
              </MenuItem>
            );
          }

          return (
            <MenuItem key={item} value={item} disabled={value === item} className={classes.label}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>)
  );
};

export default React.memo(DropDown);

DropDown.defaultProps = {
  label: '',
  disabled: false,
  small: false,
  value: '',
  dataCy: ''
};

DropDown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  keyword: PropTypes.string.isRequired,
  dataCy: PropTypes.string
};
