import {getEnviroment} from 'config/config';

import {OrganizationServiceClient, PortfolioServiceClient} from '../../api/organization_grpc_web_pb';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const PortfolioService = new PortfolioServiceClient(ENV);
export const OrganizationService = new OrganizationServiceClient(ENV);

enableDevTools([
    PortfolioService,
    OrganizationService
]);