import React from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import FootFallItem from '../FootFallItem';
import useStyles from '../footfallstyles';

const FootFallList = ({ data, handleSave }) => {
  const classes = useStyles();

  return (
    <Box width="100%" bgcolor="#F8F9FA" p={2}>
      <Box pt={3} pb={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.footerItem}>Date</Typography>
        <Typography className={classes.footerItem}>Footfall</Typography>
      </Box>

      {data.map((item) => (
        <FootFallItem item={item} key={item.name} handleSave={handleSave} />
      ))}
    </Box>
  );
};

export default React.memo(FootFallList);

FootFallList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired
};
