import React from 'react';

import { Dialog, Box, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fonts.base,
    fontSize: 20,
    color: theme.palette.common.white
  },
  titleRoot: {
    display: 'flex',
    flexDirection: 'row'
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 26,
    cursor: 'pointer'
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    margin: theme.spacing(2, 0)
  },
  input: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  responsive: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      minWidth: 800
    }
  },
  uploadText: {
    margin: theme.spacing(2, 0),
    fontFamily: theme.fonts.base
  }
}));

const EditForm = ({ open, handleClose, handleSave, procurement }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState(procurement);

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  React.useEffect(() => {
    setForm(procurement);
  }, [procurement]);

  return (
    (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.title}>New Provider</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <TextField
            fullWidth
            id="companyname-provider"
            label="Company name"
            variant="outlined"
            className={classes.textField}
            value={form.name}
            onChange={(e) => handleChange('name', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />

          <TextField
            fullWidth
            id="description-provider"
            label="Description"
            variant="outlined"
            className={classes.textField}
            value={form.description}
            multiline
            rows={4}
            onChange={(e) => handleChange('description', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleClose} />
          <SaveButton onClick={() => handleSave(form)} />
        </Box>
      </DialogActions>
    </Dialog>)
  );
};

export default EditForm;

EditForm.defaultProps = {
  handleSave: () => {},
  procurement: { name: '', description: '' }
};

EditForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  procurement: PropTypes.objectOf(PropTypes.any)
};
