import React from 'react';

import {Box, Divider, Paper, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ActivityFeed from 'components/ActivityFeed';
import Crumps from 'components/Breadcrumps/Crumps';
import EditButton from 'components/Buttons/EditButton';
import {closeModal, openModal} from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import AddCostManager from 'forms/AddCostManager';
import useActivityFeed from 'hooks/useActivityFeed';
import useRouter from 'hooks/useRouter';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

import TenantCostContractDetails from './TenantContractDetails';
import Procurement from './TenantProcurement';
import SupplierDetails from './TenantSupplierDetails';

const TenantCostManagerFlow = () => {
    const {query} = useRouter();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const history = useRouter();
    const loaded = useSelector((state) => state.costManager.costManagerDetailsLoaded);
    const loading = useSelector((state) => state.costManager.costManagerDetailsLoading);
    const costManager = useSelector((state) => state.costManager.costManagerDetails);
    const error = useSelector((state) => state.costManager.costManagerDetailsError);
    const crumps = useSelector((state) => state.tenants.crumps.crumps);
    const {costManager: costManagers, loaded: costManagerLoaded} = useSelector((state) => state.costManager);

    const {activityFeed, fetch} = useActivityFeed({
        id: query.index,
        tenantLevel: true
    });

    React.useEffect(() => {
        if (!query.id) {
            return;
        }
        dispatch(actions.getTenantCrumps(query.id));
        dispatch(actions.getTenantCostManagerDetails(query.index));
        dispatch(actions.getTenantCostManagerProcurement(query.index));
        dispatch(actions.getTenantCostSupplierDetails(query.index));
        dispatch(actions.getTenantCostContractDetails(query.index));
        dispatch(actions.getTenantCostManager(query.id));
    }, []);

    React.useEffect(
        () => () => {
            dispatch(actions.resetTenantCostManager());
        },
        []
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDelete = () => {
        dispatch(actions.deleteTenantCostManager(query.index)).then(() => {
            toast.success('Tenant Cost Manager deleted');

            history.push(`/Tenants/${query.id}/CostManager`);
        });
    };

    const handleConfirm = () => {
        dispatch(
            openModal({
                modalType: 'AlertModal',
                modalProps: {
                    content: 'Are you sure you want to delete this Item, this action cannot be undone',
                    title: 'Delete Cost Manager',
                    handleConfirm: () => {
                        handleDelete();
                        dispatch(closeModal());
                    }
                }
            })
        );
    };

    const handleUpdate = (name) => {
        dispatch(actions.updateTenantCostManagerDetails(query.index, name))
            .then(() => {
                dispatch(actions.getTenantCostManagerDetails(query.index));
                fetch();
                toast.success('TenantCost updated');
                setOpen(false);
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    if (!loaded || loading || !costManagerLoaded) {
        return <Spinner/>;
    }

    if (error) {
        return (
            <Box ml={5} mt={5}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box height="100%">
            <Paper className={classes.root}>
                <Crumps
                    fragments={[
                        {title: crumps.assetname, href: `/Asset/${crumps.assetid}`},
                        {title: crumps.tenantname, href: `/Tenants/${crumps.tenantid}`},
                        {title: 'Cost Manager', href: `/Tenants/${crumps.tenantid}/CostManager`},
                        {
                            title: costManager.name,
                            href: `/Tenants/${crumps.tenantid}/CostManager/${query.index}`
                        }
                    ]}
                />

                <Box padding={2} margin={2}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5" className={classes.headerText}>
                            {costManager.name}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <DeleteIcon className={classes.editIcon} onClick={handleConfirm}/>

                            <EditButton onClick={() => setOpen(true)}/>
                            <AddCostManager
                                open={open}
                                handleClose={() => setOpen(false)}
                                costManager={costManager.name}
                                createCostManager={handleUpdate}
                                title="Edit Cost Manager"
                                uniqueCheck={(value) => costManagers.find((element) => element.name === value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Divider/>
                    <Tabs
                        value={value}
                        handleChange={handleChange}
                        tabNames={['Procurement', 'Supplier Details', 'Contract Details', 'Activity Feed']}
                    />
                </Box>
            </Paper>

            <TabPanel value={value} index={0}>
                <Procurement/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SupplierDetails id={query.index}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TenantCostContractDetails id={query.index}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ActivityFeed activityFeed={activityFeed} nowrap/>
            </TabPanel>
        </Box>
    );
};

export default TenantCostManagerFlow;
