import React, { useState, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Store, Description, Apartment, Sort, Delete } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EditButton from 'components/Buttons/EditButton';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import { isContractExpired } from 'helpers/utils';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import LeasingManagerHeader from 'Workflows/Tenant/LeasingManager/LeasingManagerHeader';

const makeBundleId = (portfolioIds) => `Page1-PortfolioIds${portfolioIds}`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  editIcon: {
    color: '#7D7D7D',
    cursor: 'pointer',
    marginRight: 12,
    fontSize: 26
  },
  headerText: {
    fontFamily: theme.fonts.baseSerif,
    fontSize: 30,
    color: theme.palette.common.black,
    fontWeight: 500
  },
  root: {
    marginBottom: 16,
    padding: theme.spacing(1)
  },
  icon: {
    fontSize: 18,
    color: theme.palette.text.lightgrey
  },
  subtitle: {
    color: theme.palette.text.grey,
    paddingLeft: theme.spacing(1),
    fontFamily: theme.fonts.openSans,
    fontSize: 14,
    marginLeft: theme.spacing(1.5)
  },
  tag: {
    color: theme.palette.text.lightgrey,
    marginLeft: theme.spacing(2),
    fontSize: 18
  },
  link: {
    fontSize: 22,
    marginLeft: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
}));

export default function Header() {
  const router = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data } = useSelector((state) => state.tenants.tenantDetails);
  const [open, setOpen] = useState(false);
  const { units } = useSelector((state) => state.leasingManager);
  const { contract: contracts } = useSelector((state) => state.leasingManager);
  const { details } = useSelector((state) => state.leasingManager.details);

  const getUnitsData = useMemo(() => {
    const filteredUnits = units?.filter((unit) => details.unitidsList?.indexOf(unit.id) > -1);
    const unitsNames = filteredUnits?.map((unit) => unit.name).join(', ');
    const unitsFloors = filteredUnits?.map((unit) => unit.floor).join(', ');
    const unitsTotalGLA = filteredUnits?.reduce((acc, unit) => acc + unit.gla, 0).toFixed(2);

    return {
      unitsNames,
      unitsFloors,
      unitsTotalGLA
    };
  }, [units, details]);

  useEffect(() => {
    dispatch(actions.getTenantLeasingDetails(contracts[0]?.id));
  }, []);

  const handleDelete = () => {
    dispatch(actions.deleteTenant(router.query.id))
      .then(() => {
        dispatch(actions.getTenants([], makeBundleId([]), [], true));
        toast.success('Tenant Deleted');
      })
      .then(() => {
        router.history.goBack();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleConfirm = () => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Delete Tenant',
          content:
            'Are you sure you want to delete the selected Tenant? This action cannot be undone',
          handleConfirm: () => {
            handleDelete();
            dispatch(closeModal());
          }
        }
      })
    );
  };

  const HandleAddContract = (form) => {
    const formCopy = {
      unitidsList: form.unitidsList,
      assetid: data.assetid,
      number: form.number,
      tenantid: data.id,
      startdate: form.startdate,
      enddate: form.enddate
    };

    dispatch(actions.createLeasingContract(formCopy))
      .then(() => {
        toast.success('Contract Added');
        setOpen(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" justifycontent="center" alignItems="center">
        <Grid
          item
          container
          direction="row"
          justifycontent="space-between"
          className={classes.spaceBetween}
        >
          <Grid item>
            <Box padding={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifycontent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" className={classes.headerText}>
                  {data.companyname}
                </Typography>

                <Box display="flex" alignItems="center">
                  <Delete className={classes.editIcon} onClick={handleConfirm} />
                  <EditButton
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: 'TenantModal',
                          modalProps: { isEdit: true, data }
                        })
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box padding={2} className={classes.hideMobile}>
              <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
                <Tooltip title="Unit name" placement="top">
                  <Box display="flex" mr={2} alignItems="center" justifycontent="center">
                    <Store className={classes.icon} />
                    <Typography className={classes.subtitle}>
                      {getUnitsData?.unitsNames || 'N/A'}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Total GLA" placement="top">
                  <Box display="flex" mr={2} alignItems="center" justifycontent="center">
                    <Apartment className={classes.tag} />
                    <Typography className={classes.subtitle}>
                      {`${getUnitsData?.unitsTotalGLA} m2`}
                    </Typography>
                  </Box>
                </Tooltip>

                <Tooltip title="Floors" placement="top">
                  <Box display="flex" mr={2} alignItems="center" justifycontent="center">
                    <Sort className={classes.tag} />
                    <Typography className={classes.subtitle}>
                      {getUnitsData?.unitsFloors || 'N/A'}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Tenant Contract Reference" placement="top">
                  <Box display="flex" mr={2} alignItems="center" justifycontent="center">
                    <MonetizationOnIcon className={classes.tag} />
                    <Typography className={classes.subtitle}>{data.tradingname}</Typography>
                  </Box>
                </Tooltip>
                <Tooltip title="Industry" placement="top">
                  <Box display="flex" mr={2} alignItems="center" justifycontent="center">
                    <BusinessIcon className={classes.tag} />
                    <Typography className={classes.subtitle}>{data.industry}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="baseline"
              flexWrap="wrap"
              padding={2}
            >
              {data?.startdate && (
                <>
                  <Description className={classes.tag} style={{ marginLeft: 0 }} />
                  <Typography className={classes.subtitle}>
                    {`Contract started: ${data?.startdate}`}
                  </Typography>
                </>
              )}
              <Typography className={classes.subtitle}>
                {`Contract Ends: ${data?.enddate}`}
              </Typography>
              {isContractExpired(data?.enddate) && (
                <Typography className={classes.padding} color="error" variant="subtitle2">
                  Expired
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item className={classes.padding}>
            <Grid
              container
              direction="column"
              justifycontent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <LeasingManagerHeader
                  unitList={units}
                  HandleAddContract={HandleAddContract}
                  show={open}
                  setShow={setOpen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
