/* eslint-disable react/prop-types */
import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch } from 'react-redux';

import Transition from '../Transition';

import { closeModal } from './modalReducer';

export default function AlertModal({ content, title, handleConfirm }) {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open
      onClose={handleCloseModal}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Box bgcolor="#2C324D" p={2} display="flex" mb={2} color="white" alignItems="center">
        <WarningIcon style={{ color: 'orange', fontSize: 30 }} />
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex" flexWrap="wrap">
          <Box ml={2} mr={2}>
            <Button onClick={handleCloseModal} color="secondary" variant="outlined">
              Cancel
            </Button>
          </Box>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
