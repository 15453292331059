import React from 'react';

import { Box, Paper, Typography, Grid } from '@mui/material';
import EditButton from 'components/Buttons/EditButton';
import UploadRightBox from 'components/Wizards/UploadRightBox';
import PropTypes from 'prop-types';

import useStyles from '../styles';

import EditForm from './SupplierDetailsEdit';
import SupplierInfo from './SupplierInfo';

const SupplierDetails = ({ pathName, openEdit, details, handleSave, setOpenEdit }) => {
  const classes = useStyles();

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={12} sm={8} md={9} style={{ marginTop: 10 }}>
          <Paper className={classes.paper}>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                className={classes.responsive}
              >
                <Typography className={classes.title}>Supplier Details</Typography>
                <EditButton onClick={() => setOpenEdit(true)} />
              </Box>
              <EditForm
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                details={details}
                handleSave={handleSave}
              />

              <SupplierInfo details={details} />
            </Box>
          </Paper>
        </Grid>

        <UploadRightBox pathName={pathName} />
      </Grid>
    </Box>
  );
};

export default SupplierDetails;

SupplierDetails.propTypes = {
  handleSave: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired,
  setOpenEdit: PropTypes.func.isRequired,
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  openEdit: PropTypes.bool.isRequired
};
