/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Summary = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Box display="flex" pt={1}>
      <Typography className={classes.accordionSubtitle}>{title}:</Typography>
      <Typography className={classes.accordionSummary}>{value}</Typography>
    </Box>
  );
};

Summary.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default Summary;
