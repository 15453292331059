import { TurnoverServiceClient } from 'api/turnover_grpc_web_pb';
import { getEnviroment } from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const DataTurnoverService = new TurnoverServiceClient(getEnviroment(env));

enableDevTools([
    DataTurnoverService
]);