import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Euro } from '@mui/icons-material';
import StatsIcon from 'components/Icons/StatsIcon';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import AccountGraph from '../AccountGraph';
import AccountWatch from '../AccountWatch';
import Invoices from '../Invoices';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 25,
    fontFamily: theme.fonts.base
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    overflow: 'auto',
    margin: theme.spacing(2, 6),
    minHeight: 400
  },
  boxContainer: {
    padding: theme.spacing(3)
  },
  boxInner: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  title: {
    fontFamily: theme.fonts.base,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  vertical: {
    height: '100%',
    minHeight: 360,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  overflow: {
    overflowX: 'auto'
  }
}));

const AssetCashManager = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();

  const loaded = useSelector((state) => state.assetCashManager.loaded);
  const loading = useSelector((state) => state.assetCashManager.loading);
  const assetCashManager = useSelector((state) => state.assetCashManager.cashManager);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  useEffect(() => {
    if (asset.id === query.id && loaded) {
      return;
    }

    if (query.id) {
      dispatch(actions.getAssetCashManager(query.id));
    }
  }, [query]);

  if (!loaded || loading) {
    return <Spinner />;
  }
  return (
    <Box pb={2}>
      <Box display="flex" alignItems="center" m={3} p={2}>
        <Typography className={classes.header}>Cash Manager</Typography>
      </Box>

      <Paper className={classes.paper}>
        <Box display="flex" className={classes.boxContainer}>
          <Box display="flex" flexDirection="column" className={classes.boxInner}>
            <Box display="flex" justifyContent="space-between" className={classes.overflow}>
              {assetCashManager.accounts?.length > 0 &&
                assetCashManager.accounts.map((account) => (
                  <StatsIcon
                    title={account.name}
                    statsValue={`€${account.value}`}
                    Icon={Euro}
                    key={account.id}
                  />
                ))}
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Grid container>
              <Grid item xs={12} md={5}>
                {assetCashManager.chartData?.length > 0 && (
                  <AccountGraph data={assetCashManager.chartData} />
                )}
              </Grid>
              <Grid item xs={false} md={1}>
                <Box mt={4} ml={6}>
                  <Divider orientation="vertical" className={classes.vertical} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <AccountWatch data={assetCashManager.accounts} />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Divider />
            </Box>
            <Invoices
              invoices={assetCashManager.invoicesAwaitingAproval ?? []}
              invoicesOwed={assetCashManager.invoicesOwed ?? []}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AssetCashManager;
