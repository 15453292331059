/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var external_filters_pb = require('./filters_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportCashFlowServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportCashFlowServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCashFlowByAssetIDRequest,
 *   !proto.external.GetCashFlowByAssetIDResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCashFlowByAssetID = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCashFlowByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetCashFlowByAssetIDRequest,
  proto.external.GetCashFlowByAssetIDResponse,
  /**
   * @param {!proto.external.GetCashFlowByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCashFlowByAssetIDRequest,
 *   !proto.external.GetCashFlowByAssetIDResponse>}
 */
const methodInfo_ReportCashFlowService_GetCashFlowByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCashFlowByAssetIDResponse,
  /**
   * @param {!proto.external.GetCashFlowByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCashFlowByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCashFlowByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCashFlowByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCashFlowByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCashFlowByAssetID',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCashFlowByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetCashFlowByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCashFlowByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCashFlowByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCashFlowByAssetID',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCashFlowByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCashFlowByTenantIDRequest,
 *   !proto.external.GetCashFlowByTenantIDResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCashFlowByTenantID = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCashFlowByTenantID',
  grpc.web.MethodType.UNARY,
  proto.external.GetCashFlowByTenantIDRequest,
  proto.external.GetCashFlowByTenantIDResponse,
  /**
   * @param {!proto.external.GetCashFlowByTenantIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowByTenantIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCashFlowByTenantIDRequest,
 *   !proto.external.GetCashFlowByTenantIDResponse>}
 */
const methodInfo_ReportCashFlowService_GetCashFlowByTenantID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCashFlowByTenantIDResponse,
  /**
   * @param {!proto.external.GetCashFlowByTenantIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowByTenantIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCashFlowByTenantIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCashFlowByTenantIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCashFlowByTenantIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCashFlowByTenantID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCashFlowByTenantID',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCashFlowByTenantID,
      callback);
};


/**
 * @param {!proto.external.GetCashFlowByTenantIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCashFlowByTenantIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCashFlowByTenantID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCashFlowByTenantID',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCashFlowByTenantID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCumulativeBaseRentChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetCumulativeBaseRentChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCumulativeBaseRentChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCumulativeBaseRentChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCumulativeBaseRentSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetCumulativeBaseRentSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCumulativeBaseRentSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCumulativeBaseRentSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCumulativeBaseRentForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetCumulativeBaseRentForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCumulativeBaseRentForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCumulativeBaseRentForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCumulativeBaseRentActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetCumulativeBaseRentActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCumulativeBaseRentActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCumulativeBaseRentActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCumulativeBaseRentActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCumulativeBaseRentActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetBaseRentChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetBaseRentChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetBaseRentChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getBaseRentChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getBaseRentChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetBaseRentSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetBaseRentSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetBaseRentSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getBaseRentSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getBaseRentSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetBaseRentForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetBaseRentForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetBaseRentForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getBaseRentForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getBaseRentForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetBaseRentActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetBaseRentActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetBaseRentActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getBaseRentActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getBaseRentActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetBaseRentActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetBaseRentActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetServiceChargeChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetServiceChargeChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetServiceChargeChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getServiceChargeChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getServiceChargeChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetServiceChargeSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetServiceChargeSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetServiceChargeSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getServiceChargeSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getServiceChargeSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetServiceChargeForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetServiceChargeForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetServiceChargeForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getServiceChargeForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getServiceChargeForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetServiceChargeActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetServiceChargeActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetServiceChargeActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getServiceChargeActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getServiceChargeActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetServiceChargeActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetServiceChargeActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCapexChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCapexChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetCapexChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCapexChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCapexChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCapexSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCapexSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetCapexSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCapexSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCapexSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCapexForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCapexForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetCapexForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCapexForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCapexForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetCapexActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetCapexActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetCapexActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getCapexActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getCapexActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetCapexActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetCapexActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOpexChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOpexChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetOpexChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOpexChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOpexChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOpexSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOpexSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetOpexSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOpexSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOpexSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOpexForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOpexForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetOpexForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOpexForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOpexForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOpexActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOpexActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetOpexActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOpexActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOpexActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOpexActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOpexActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetTurnoverChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetTurnoverChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetTurnoverChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getTurnoverChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getTurnoverChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetTurnoverSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetTurnoverSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetTurnoverSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getTurnoverSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getTurnoverSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetTurnoverForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetTurnoverForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetTurnoverForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getTurnoverForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getTurnoverForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetTurnoverActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetTurnoverActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetTurnoverActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getTurnoverActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getTurnoverActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetTurnoverActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetTurnoverActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetNoiChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetNoiChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetNoiChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getNoiChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getNoiChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetNoiSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetNoiSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetNoiSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getNoiSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getNoiSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetNoiForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetNoiForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetNoiForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getNoiForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getNoiForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetNoiActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetNoiActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetNoiActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getNoiActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getNoiActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetNoiActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetNoiActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetLeasingFeeChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetLeasingFeeChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetLeasingFeeChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getLeasingFeeChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getLeasingFeeChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetLeasingFeeSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetLeasingFeeSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetLeasingFeeSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getLeasingFeeSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getLeasingFeeSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetLeasingFeeForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetLeasingFeeForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetLeasingFeeForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getLeasingFeeForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getLeasingFeeForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetLeasingFeeActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetLeasingFeeActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetLeasingFeeActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getLeasingFeeActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getLeasingFeeActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetLeasingFeeActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetLeasingFeeActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetFitOutChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetFitOutChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetFitOutChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getFitOutChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getFitOutChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetFitOutSummaryTable = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetFitOutSummaryTable',
  grpc.web.MethodType.UNARY,
  proto.external.SummaryTableRequest,
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.SummaryTableRequest,
 *   !proto.external.SummaryTableResponse>}
 */
const methodInfo_ReportCashFlowService_GetFitOutSummaryTable = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.SummaryTableResponse,
  /**
   * @param {!proto.external.SummaryTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.SummaryTableResponse.deserializeBinary
);


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.SummaryTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.SummaryTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getFitOutSummaryTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutSummaryTable,
      callback);
};


/**
 * @param {!proto.external.SummaryTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.SummaryTableResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getFitOutSummaryTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutSummaryTable',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutSummaryTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetFitOutForecasts = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetFitOutForecasts',
  grpc.web.MethodType.UNARY,
  proto.external.ForecastsRequest,
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ForecastsRequest,
 *   !proto.external.ForecastsResponse>}
 */
const methodInfo_ReportCashFlowService_GetFitOutForecasts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ForecastsResponse,
  /**
   * @param {!proto.external.ForecastsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ForecastsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ForecastsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ForecastsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getFitOutForecasts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutForecasts,
      callback);
};


/**
 * @param {!proto.external.ForecastsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ForecastsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getFitOutForecasts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutForecasts',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutForecasts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetFitOutActuals = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetFitOutActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ActualsRequest,
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ActualsRequest,
 *   !proto.external.ActualsResponse>}
 */
const methodInfo_ReportCashFlowService_GetFitOutActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ActualsResponse,
  /**
   * @param {!proto.external.ActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ActualsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ActualsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ActualsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getFitOutActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutActuals,
      callback);
};


/**
 * @param {!proto.external.ActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ActualsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getFitOutActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetFitOutActuals',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetFitOutActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOtherIncomeChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOtherIncomeChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetOtherIncomeChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOtherIncomeChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOtherIncomeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOtherIncomeChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOtherIncomeChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOtherIncomeChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOtherIncomeChartData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodDescriptor_ReportCashFlowService_GetOtherExpensesChartData = new grpc.web.MethodDescriptor(
  '/external.ReportCashFlowService/GetOtherExpensesChartData',
  grpc.web.MethodType.UNARY,
  proto.external.ChartDataRequest,
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ChartDataRequest,
 *   !proto.external.ChartDataResponse>}
 */
const methodInfo_ReportCashFlowService_GetOtherExpensesChartData = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ChartDataResponse,
  /**
   * @param {!proto.external.ChartDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ChartDataResponse.deserializeBinary
);


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ChartDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ChartDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportCashFlowServiceClient.prototype.getOtherExpensesChartData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOtherExpensesChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOtherExpensesChartData,
      callback);
};


/**
 * @param {!proto.external.ChartDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ChartDataResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportCashFlowServicePromiseClient.prototype.getOtherExpensesChartData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportCashFlowService/GetOtherExpensesChartData',
      request,
      metadata || {},
      methodDescriptor_ReportCashFlowService_GetOtherExpensesChartData);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportFootfallServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportFootfallServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAggregateFootfallsRequest,
 *   !proto.external.AggregateFootfallResponse>}
 */
const methodDescriptor_ReportFootfallService_GetAggregateFootfall = new grpc.web.MethodDescriptor(
  '/external.ReportFootfallService/GetAggregateFootfall',
  grpc.web.MethodType.UNARY,
  proto.external.GetAggregateFootfallsRequest,
  proto.external.AggregateFootfallResponse,
  /**
   * @param {!proto.external.GetAggregateFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AggregateFootfallResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAggregateFootfallsRequest,
 *   !proto.external.AggregateFootfallResponse>}
 */
const methodInfo_ReportFootfallService_GetAggregateFootfall = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AggregateFootfallResponse,
  /**
   * @param {!proto.external.GetAggregateFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AggregateFootfallResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetAggregateFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AggregateFootfallResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AggregateFootfallResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportFootfallServiceClient.prototype.getAggregateFootfall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportFootfallService/GetAggregateFootfall',
      request,
      metadata || {},
      methodDescriptor_ReportFootfallService_GetAggregateFootfall,
      callback);
};


/**
 * @param {!proto.external.GetAggregateFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AggregateFootfallResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportFootfallServicePromiseClient.prototype.getAggregateFootfall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportFootfallService/GetAggregateFootfall',
      request,
      metadata || {},
      methodDescriptor_ReportFootfallService_GetAggregateFootfall);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportTurnoverServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportTurnoverServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAggregateTurnoversRequest,
 *   !proto.external.AggregateTurnoverResponse>}
 */
const methodDescriptor_ReportTurnoverService_GetAggregateTurnover = new grpc.web.MethodDescriptor(
  '/external.ReportTurnoverService/GetAggregateTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.GetAggregateTurnoversRequest,
  proto.external.AggregateTurnoverResponse,
  /**
   * @param {!proto.external.GetAggregateTurnoversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AggregateTurnoverResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAggregateTurnoversRequest,
 *   !proto.external.AggregateTurnoverResponse>}
 */
const methodInfo_ReportTurnoverService_GetAggregateTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AggregateTurnoverResponse,
  /**
   * @param {!proto.external.GetAggregateTurnoversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AggregateTurnoverResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetAggregateTurnoversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AggregateTurnoverResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AggregateTurnoverResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportTurnoverServiceClient.prototype.getAggregateTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportTurnoverService/GetAggregateTurnover',
      request,
      metadata || {},
      methodDescriptor_ReportTurnoverService_GetAggregateTurnover,
      callback);
};


/**
 * @param {!proto.external.GetAggregateTurnoversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AggregateTurnoverResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportTurnoverServicePromiseClient.prototype.getAggregateTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportTurnoverService/GetAggregateTurnover',
      request,
      metadata || {},
      methodDescriptor_ReportTurnoverService_GetAggregateTurnover);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportTenantPerformanceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportTenantPerformanceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantPerformanceRequest,
 *   !proto.external.TenantPerformanceResponse>}
 */
const methodDescriptor_ReportTenantPerformanceService_GetTenantPerformance = new grpc.web.MethodDescriptor(
  '/external.ReportTenantPerformanceService/GetTenantPerformance',
  grpc.web.MethodType.UNARY,
  proto.external.TenantPerformanceRequest,
  proto.external.TenantPerformanceResponse,
  /**
   * @param {!proto.external.TenantPerformanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantPerformanceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantPerformanceRequest,
 *   !proto.external.TenantPerformanceResponse>}
 */
const methodInfo_ReportTenantPerformanceService_GetTenantPerformance = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantPerformanceResponse,
  /**
   * @param {!proto.external.TenantPerformanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantPerformanceResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantPerformanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantPerformanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantPerformanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportTenantPerformanceServiceClient.prototype.getTenantPerformance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportTenantPerformanceService/GetTenantPerformance',
      request,
      metadata || {},
      methodDescriptor_ReportTenantPerformanceService_GetTenantPerformance,
      callback);
};


/**
 * @param {!proto.external.TenantPerformanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantPerformanceResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportTenantPerformanceServicePromiseClient.prototype.getTenantPerformance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportTenantPerformanceService/GetTenantPerformance',
      request,
      metadata || {},
      methodDescriptor_ReportTenantPerformanceService_GetTenantPerformance);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportAssetPerformanceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ReportAssetPerformanceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.AssetPerformanceRequest,
 *   !proto.external.AssetPerformanceResponse>}
 */
const methodDescriptor_ReportAssetPerformanceService_GetAssetPerformance = new grpc.web.MethodDescriptor(
  '/external.ReportAssetPerformanceService/GetAssetPerformance',
  grpc.web.MethodType.UNARY,
  proto.external.AssetPerformanceRequest,
  proto.external.AssetPerformanceResponse,
  /**
   * @param {!proto.external.AssetPerformanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetPerformanceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.AssetPerformanceRequest,
 *   !proto.external.AssetPerformanceResponse>}
 */
const methodInfo_ReportAssetPerformanceService_GetAssetPerformance = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AssetPerformanceResponse,
  /**
   * @param {!proto.external.AssetPerformanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetPerformanceResponse.deserializeBinary
);


/**
 * @param {!proto.external.AssetPerformanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AssetPerformanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AssetPerformanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ReportAssetPerformanceServiceClient.prototype.getAssetPerformance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ReportAssetPerformanceService/GetAssetPerformance',
      request,
      metadata || {},
      methodDescriptor_ReportAssetPerformanceService_GetAssetPerformance,
      callback);
};


/**
 * @param {!proto.external.AssetPerformanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AssetPerformanceResponse>}
 *     Promise that resolves to the response
 */
proto.external.ReportAssetPerformanceServicePromiseClient.prototype.getAssetPerformance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ReportAssetPerformanceService/GetAssetPerformance',
      request,
      metadata || {},
      methodDescriptor_ReportAssetPerformanceService_GetAssetPerformance);
};


module.exports = proto.external;

