import React from 'react';

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NewButton from 'components/Buttons/NewButton';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';

import EditForm from './EditForm';
import Provider from './Provider';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fonts.base,
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: 500
  },
  box: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  }
}));

const Procurement = ({
  pathName,
  handleSave,
  handleDelete,
  loading,
  loaded,
  error,
  procurement,
  openEdit,
  setOpenEdit
}) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({ name: '', description: '' });

  const handleNew = React.useCallback(() => {
    setOpenEdit(true);
    setForm({ name: '', description: '' });
  }, []);

  const handleOpenEditForm = React.useCallback((item) => {
    setForm(item);
    setOpenEdit(true);
  }, []);

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box ml={5} mt={5}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box style={loading ? { opacity: 0.5, pointerEvents: 'none' } : {}} m={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m={2}
        pb={2}
        className={classes.box}
      >
        <Typography className={classes.title}>{`Providers (${procurement.length})`}</Typography>
        <Box pt={1}>
          <NewButton onClick={handleNew} label="New Provider" />
        </Box>
      </Box>
      <EditForm
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        handleSave={handleSave}
        procurement={form}
      />

      <Box>
        {procurement.length > 0 &&
          procurement.map((item) => (
            <Provider
              key={item.id}
              item={item}
              handleOpenEditForm={handleOpenEditForm}
              handleDelete={handleDelete}
              pathName={`${pathName}/Procurement/${item.id}`}
            />
          ))}
      </Box>
    </Box>
  );
};

export default React.memo(Procurement);

Procurement.defaultProps = {
  error: ''
};

Procurement.propTypes = {
  pathName: PropTypes.string.isRequired,
  procurement: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  openEdit: PropTypes.bool.isRequired,
  setOpenEdit: PropTypes.func.isRequired
};
