import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 64,
    backgroundColor: '#1B2740',
    padding: 0,
    margin: 0,
    position: 'relative'
  },
  growHorizontal: {
    flexGrow: 1
  },
  blue: {
    backgroundColor: ' #009ADE!important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    cursor: 'pointer',
    marginLeft: 12
  },
  link: {
    textDecoration: 'none'
  },
  logo: {
    height: 30,
    width: 'auto'
  },
  logoTextTop: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.02em'
  },
  logoTextBottom: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '19px',
    letterSpacing: '-0.06em'
  },
  menuItemMobile: {
    justifyContent: 'center'
  },
  menuButton: {
    marginRight: theme.spacing(5),
    width: '64px',
    height: '64px',
    marginLeft: theme.spacing(1)
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 18px'
  },
  menuDesktop: {
    marginTop: theme.spacing(1)
  },
  productName: {
    marginLeft: '12px',
    '& > *': {
      color: '#FFF',
      margin: theme.spacing(0),
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    }
  },
  drawerPaper: {
    width: 240,
    top: 'unset',
    backgroundColor: '#1B2740',
    '& .Mui-selected': {
      borderLeft: '5px solid #009ADE'
    }
  },
  listItem: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-0.01em',
    color: ' #FFFFFF',
    '&:hover': {
      backgroundColor: '#009ADE'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  white: {
    color: '#FFF'
  },
  customBadge: {
    backgroundColor: 'orange',
    color: 'white'
  },
  iconBtn: {
    '&:hover': {
      backgroundColor: 'rgba(221, 221, 221, 0.25) !important'
    }
  },
  iconButton: {
    padding: theme.spacing(3)
  },
  activeLink: {
    '&::after': {
      content: '""',
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      marginTop: theme.spacing(2.3),
      marginBottom: -theme.spacing(2.3)
    }
  },
  navLinkContainer: {
    flexDirection: 'row',
    display: 'flex',
    flexGrow: 1,
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  navLink: {
    color: 'white',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0, 2),
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      color: 'black',
      margin: 0,
      padding: theme.spacing(0, 3)
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(0.5)
    },
    '&:hover': {
      opacity: 0.8
    }
  },
  navText: {
    fontSize: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    }
  },
}));

export default useStyles;
