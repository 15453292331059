import React, {Suspense, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ApexChart from 'components/Charts';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import {formatNumberToCurrency} from 'helpers/utils';
import useRouter from 'hooks/useRouter';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import * as actions from 'store/actions';
import {config} from 'views/HomeScreen/chartConfig';

import useStyles from './styles';
import TableActualsForecasts from "../../HomeScreen/ChartDetails/TableActualsForecasts";
import TableSummary from "../../HomeScreen/ChartDetails/TableSummary";

const namesMapper = {
    BaseRent: 'Base Rent',
    TurnoverRent: 'Turnover Rent',
    ServiceCharge: 'Service Charge',
    OtherIncome: 'Other Income',
    CumulativeRent: 'Cumulative Rent',
    NOI: 'NOI',
    Opex: 'Opex',
    Capex: 'Capex',
    OtherExpenses: 'Other Expenses',
    LeasingFee: 'Leasing Fee',
    FitOut: 'Fit Out'
};

const AssetChartDetails = () => {
    const yearOptions = useSelector((state) => state.home.homeDataYears);
    const year = useSelector((state) => state.home.yearFilter);
    const {data, error, loaded} = useSelector((state) => state.home.chartDataPage);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        data: summaryData,
        loading: summaryDataLoading,
        loaded: summaryDataLoaded
    } = useSelector((state) => state.home.summaryData);
    const {data: forecastsData} = useSelector((state) => state.home.forecasts);
    const {data: actualsData} = useSelector((state) => state.home.actuals);
    const {
        params: {tab, assetId: id}
    } = useRouteMatch();
    const router = useRouter();
    const queryParam = new URLSearchParams(router.location.search);
    const [fromValue, setFromValue] = useState('');
    const [locationSearch, setLocationSearch] = useState('');

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    useEffect(() => {
        if (queryParam.get('from')) {
            setFromValue(queryParam.get('from'));
        }
    }, [fromValue]);

    useEffect(() => {
        if (router.location.search) {
            setLocationSearch(router.location.search);
        }
    }, [locationSearch]);


    useEffect(() => {
        dispatch(actions.getFilterYears());
        dispatch(
            actions.getChartDataPerPage(tab, {PortfolioID: [], AssetID: [router.query.id], Year: [year]})
        );
        dispatch(
            actions.getSummaryTableData(tab, {PortfolioID: [], AssetID: [router.query.id], Year: [year]})
        );
        dispatch(actions.getTenantsActuals(tab, {PortfolioID: [], AssetID: [router.query.id], Year: [year]}));
        dispatch(
            actions.getTenantsForecasts(tab, {PortfolioID: [], AssetID: [router.query.id], Year: [year]})
        );
    }, [tab, year]);

    const handleSelectYear = (e) => {
        dispatch(actions.setYearFilter(e.target.value));
    };

    if (error && loaded) {
        return (
            <Typography color="error" style={{marginLeft: 30, marginTop: 30}}>
                {error}
            </Typography>
        );
    }

    return (
        <Box style={{paddingTop: 8, marginLeft: 24}}>
            <Select
                options={yearOptions}
                label="Year"
                keyword="label"
                value={year.toString()}
                handleChange={handleSelectYear}
            />
            <Paper style={{padding: '20px'}}>
                <Box>
                    <Box style={{padding: '10px'}}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4">{namesMapper[tab]}</Typography>
                        </Box>

                        <Box className={classes.wrapCard}>
                            <Typography variant="h6">
                                {`Actuals ${year}: ${formatNumberToCurrency(
                                    data?.actualforecastsList?.reduce((acc, cur) => acc + cur, 0)
                                )}`}
                            </Typography>
                        </Box>
                        <Box className={classes.wrapCard}>
                            <Typography variant="h6">
                                {`Total Forecasted ${year}: ${formatNumberToCurrency(
                                    data?.forecastsList?.reduce((acc, cur) => acc + cur, 0)
                                )}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{height: '500px'}}>
                        <Suspense fallback={<Spinner mini/>}>
                            <ApexChart
                                height={350}
                                options={config.options}
                                series={[
                                    {
                                        name: `${namesMapper[tab]} Forecasts`,
                                        data: summaryData?.totalforecastsList || []
                                    },
                                    {
                                        name: `${namesMapper[tab]} Actuals`,
                                        data: data?.actualforecastsList || []
                                    }
                                ]}
                            />
                        </Suspense>
                    </Box>
                </Box>
                {summaryData && (
                    <Box className={classes.summaryContainer}>
                        <TableSummary
                            data={summaryData || null}
                            loading={summaryDataLoading}
                            loaded={summaryDataLoaded}
                            tab={tab}
                        />
                    </Box>
                )}
            </Paper>
            <br></br>
            <Paper elevation={3} className={classes.margin} style={{overflowX: 'scroll'}}>
                <Box>
                    <Typography className={classes.padding}>Forecasts</Typography>
                </Box>
                <Box>
                    <TableActualsForecasts
                        data={forecastsData || []}
                        name="forecastsList"
                        tab={tab}
                        assetId={router.query.id}
                    />
                </Box>
            </Paper>
            <br></br>
            <Paper elevation={3} className={classes.margin} style={{overflowX: 'scroll'}}>
                <Box>
                    <Typography className={classes.padding}>Actuals</Typography>
                </Box>
                <Box>
                    <TableActualsForecasts
                        data={actualsData || []}
                        name="actualsList"
                        tab={tab}
                        assetId={router.query.id}
                    />
                </Box>
            </Paper>
        </Box>
    );
};
export default AssetChartDetails;
