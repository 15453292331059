// source: external/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var external_filters_pb = require('./filters_pb.js');
goog.object.extend(proto, external_filters_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.ActualRow', null, global);
goog.exportSymbol('proto.external.ActualsRequest', null, global);
goog.exportSymbol('proto.external.ActualsResponse', null, global);
goog.exportSymbol('proto.external.AggregateFootfallItem', null, global);
goog.exportSymbol('proto.external.AggregateFootfallResponse', null, global);
goog.exportSymbol('proto.external.AggregateTurnoverItem', null, global);
goog.exportSymbol('proto.external.AggregateTurnoverResponse', null, global);
goog.exportSymbol('proto.external.AssetPerformanceRequest', null, global);
goog.exportSymbol('proto.external.AssetPerformanceResponse', null, global);
goog.exportSymbol('proto.external.BarChartSeriesItem', null, global);
goog.exportSymbol('proto.external.CashFlow', null, global);
goog.exportSymbol('proto.external.CashFlow.Item', null, global);
goog.exportSymbol('proto.external.CashFlowMonthValue', null, global);
goog.exportSymbol('proto.external.CashFlowReportItem', null, global);
goog.exportSymbol('proto.external.ChartDataRequest', null, global);
goog.exportSymbol('proto.external.ChartDataRequest.AnnualData', null, global);
goog.exportSymbol('proto.external.ChartDataRequest.Period', null, global);
goog.exportSymbol('proto.external.ChartDataResponse', null, global);
goog.exportSymbol('proto.external.FootfallAggregateType', null, global);
goog.exportSymbol('proto.external.ForecastRow', null, global);
goog.exportSymbol('proto.external.ForecastsRequest', null, global);
goog.exportSymbol('proto.external.ForecastsResponse', null, global);
goog.exportSymbol('proto.external.GetAggregateFootfallsRequest', null, global);
goog.exportSymbol('proto.external.GetAggregateTurnoversRequest', null, global);
goog.exportSymbol('proto.external.GetCashFlowByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetCashFlowByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetCashFlowByTenantIDRequest', null, global);
goog.exportSymbol('proto.external.GetCashFlowByTenantIDResponse', null, global);
goog.exportSymbol('proto.external.SummaryTableRequest', null, global);
goog.exportSymbol('proto.external.SummaryTableResponse', null, global);
goog.exportSymbol('proto.external.TenantPerformanceRequest', null, global);
goog.exportSymbol('proto.external.TenantPerformanceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ChartDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ChartDataRequest.repeatedFields_, null);
};
goog.inherits(proto.external.ChartDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ChartDataRequest.displayName = 'proto.external.ChartDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ChartDataRequest.AnnualData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.ChartDataRequest.AnnualData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ChartDataRequest.AnnualData.displayName = 'proto.external.ChartDataRequest.AnnualData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ChartDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ChartDataResponse.repeatedFields_, null);
};
goog.inherits(proto.external.ChartDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ChartDataResponse.displayName = 'proto.external.ChartDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCashFlowByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowByAssetIDRequest.displayName = 'proto.external.GetCashFlowByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowByTenantIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCashFlowByTenantIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowByTenantIDRequest.displayName = 'proto.external.GetCashFlowByTenantIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCashFlowByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCashFlowByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowByAssetIDResponse.displayName = 'proto.external.GetCashFlowByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowByTenantIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCashFlowByTenantIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCashFlowByTenantIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowByTenantIDResponse.displayName = 'proto.external.GetCashFlowByTenantIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CashFlow.repeatedFields_, null);
};
goog.inherits(proto.external.CashFlow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlow.displayName = 'proto.external.CashFlow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlow.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CashFlow.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlow.Item.displayName = 'proto.external.CashFlow.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowMonthValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CashFlowMonthValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowMonthValue.displayName = 'proto.external.CashFlowMonthValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowReportItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CashFlowReportItem.repeatedFields_, null);
};
goog.inherits(proto.external.CashFlowReportItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowReportItem.displayName = 'proto.external.CashFlowReportItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AggregateFootfallItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AggregateFootfallItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AggregateFootfallItem.displayName = 'proto.external.AggregateFootfallItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AggregateFootfallResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.AggregateFootfallResponse.repeatedFields_, null);
};
goog.inherits(proto.external.AggregateFootfallResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AggregateFootfallResponse.displayName = 'proto.external.AggregateFootfallResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.BarChartSeriesItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.BarChartSeriesItem.repeatedFields_, null);
};
goog.inherits(proto.external.BarChartSeriesItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.BarChartSeriesItem.displayName = 'proto.external.BarChartSeriesItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAggregateFootfallsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetAggregateFootfallsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAggregateFootfallsRequest.displayName = 'proto.external.GetAggregateFootfallsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AggregateTurnoverItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AggregateTurnoverItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AggregateTurnoverItem.displayName = 'proto.external.AggregateTurnoverItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AggregateTurnoverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.AggregateTurnoverResponse.repeatedFields_, null);
};
goog.inherits(proto.external.AggregateTurnoverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AggregateTurnoverResponse.displayName = 'proto.external.AggregateTurnoverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAggregateTurnoversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetAggregateTurnoversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAggregateTurnoversRequest.displayName = 'proto.external.GetAggregateTurnoversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantPerformanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantPerformanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantPerformanceResponse.displayName = 'proto.external.TenantPerformanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantPerformanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantPerformanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantPerformanceRequest.displayName = 'proto.external.TenantPerformanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetPerformanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetPerformanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetPerformanceRequest.displayName = 'proto.external.AssetPerformanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetPerformanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetPerformanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetPerformanceResponse.displayName = 'proto.external.AssetPerformanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.SummaryTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.SummaryTableRequest.repeatedFields_, null);
};
goog.inherits(proto.external.SummaryTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.SummaryTableRequest.displayName = 'proto.external.SummaryTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.SummaryTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.SummaryTableResponse.repeatedFields_, null);
};
goog.inherits(proto.external.SummaryTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.SummaryTableResponse.displayName = 'proto.external.SummaryTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ForecastsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ForecastsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.ForecastsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ForecastsRequest.displayName = 'proto.external.ForecastsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ForecastsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ForecastsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.ForecastsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ForecastsResponse.displayName = 'proto.external.ForecastsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ForecastRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ForecastRow.repeatedFields_, null);
};
goog.inherits(proto.external.ForecastRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ForecastRow.displayName = 'proto.external.ForecastRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ActualsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ActualsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.ActualsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ActualsRequest.displayName = 'proto.external.ActualsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ActualsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ActualsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.ActualsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ActualsResponse.displayName = 'proto.external.ActualsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ActualRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ActualRow.repeatedFields_, null);
};
goog.inherits(proto.external.ActualRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ActualRow.displayName = 'proto.external.ActualRow';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ChartDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ChartDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ChartDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ChartDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance),
    period: jspb.Message.getFieldWithDefault(msg, 2, 0),
    annualdata: (f = msg.getAnnualdata()) && proto.external.ChartDataRequest.AnnualData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ChartDataRequest}
 */
proto.external.ChartDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ChartDataRequest;
  return proto.external.ChartDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ChartDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ChartDataRequest}
 */
proto.external.ChartDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 2:
      var value = /** @type {!proto.external.ChartDataRequest.Period} */ (reader.readEnum());
      msg.setPeriod(value);
      break;
    case 3:
      var value = new proto.external.ChartDataRequest.AnnualData;
      reader.readMessage(value,proto.external.ChartDataRequest.AnnualData.deserializeBinaryFromReader);
      msg.setAnnualdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ChartDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ChartDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ChartDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAnnualdata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.external.ChartDataRequest.AnnualData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.external.ChartDataRequest.Period = {
  UNKNOWN: 0,
  MONTH: 1,
  YEAR: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ChartDataRequest.AnnualData.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ChartDataRequest.AnnualData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ChartDataRequest.AnnualData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataRequest.AnnualData.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    length: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ChartDataRequest.AnnualData}
 */
proto.external.ChartDataRequest.AnnualData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ChartDataRequest.AnnualData;
  return proto.external.ChartDataRequest.AnnualData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ChartDataRequest.AnnualData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ChartDataRequest.AnnualData}
 */
proto.external.ChartDataRequest.AnnualData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ChartDataRequest.AnnualData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ChartDataRequest.AnnualData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ChartDataRequest.AnnualData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataRequest.AnnualData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.external.ChartDataRequest.AnnualData.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.ChartDataRequest.AnnualData} returns this
 */
proto.external.ChartDataRequest.AnnualData.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 length = 2;
 * @return {number}
 */
proto.external.ChartDataRequest.AnnualData.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.ChartDataRequest.AnnualData} returns this
 */
proto.external.ChartDataRequest.AnnualData.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.ChartDataRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.ChartDataRequest} returns this
*/
proto.external.ChartDataRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.ChartDataRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ChartDataRequest} returns this
 */
proto.external.ChartDataRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * optional Period period = 2;
 * @return {!proto.external.ChartDataRequest.Period}
 */
proto.external.ChartDataRequest.prototype.getPeriod = function() {
  return /** @type {!proto.external.ChartDataRequest.Period} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.external.ChartDataRequest.Period} value
 * @return {!proto.external.ChartDataRequest} returns this
 */
proto.external.ChartDataRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AnnualData annualData = 3;
 * @return {?proto.external.ChartDataRequest.AnnualData}
 */
proto.external.ChartDataRequest.prototype.getAnnualdata = function() {
  return /** @type{?proto.external.ChartDataRequest.AnnualData} */ (
    jspb.Message.getWrapperField(this, proto.external.ChartDataRequest.AnnualData, 3));
};


/**
 * @param {?proto.external.ChartDataRequest.AnnualData|undefined} value
 * @return {!proto.external.ChartDataRequest} returns this
*/
proto.external.ChartDataRequest.prototype.setAnnualdata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.ChartDataRequest} returns this
 */
proto.external.ChartDataRequest.prototype.clearAnnualdata = function() {
  return this.setAnnualdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ChartDataRequest.prototype.hasAnnualdata = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ChartDataResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ChartDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ChartDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ChartDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastmonthrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currentmonthrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    growth: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    forecastsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    actualforecastsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ChartDataResponse}
 */
proto.external.ChartDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ChartDataResponse;
  return proto.external.ChartDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ChartDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ChartDataResponse}
 */
proto.external.ChartDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastmonthrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentmonthrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGrowth(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addForecasts(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActualforecasts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ChartDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ChartDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ChartDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ChartDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastmonthrent();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrentmonthrent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGrowth();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getActualforecastsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
};


/**
 * optional double lastMonthRent = 1;
 * @return {number}
 */
proto.external.ChartDataResponse.prototype.getLastmonthrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.setLastmonthrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double currentMonthRent = 2;
 * @return {number}
 */
proto.external.ChartDataResponse.prototype.getCurrentmonthrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.setCurrentmonthrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double growth = 3;
 * @return {number}
 */
proto.external.ChartDataResponse.prototype.getGrowth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.setGrowth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated double forecasts = 4;
 * @return {!Array<number>}
 */
proto.external.ChartDataResponse.prototype.getForecastsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.setForecastsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.addForecasts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};


/**
 * repeated double actualForecasts = 5;
 * @return {!Array<number>}
 */
proto.external.ChartDataResponse.prototype.getActualforecastsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.setActualforecastsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.addActualforecasts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ChartDataResponse} returns this
 */
proto.external.ChartDataResponse.prototype.clearActualforecastsList = function() {
  return this.setActualforecastsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetCashFlowByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetCashFlowByAssetIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetCashFlowByAssetIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByAssetIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowByAssetIDRequest}
 */
proto.external.GetCashFlowByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowByAssetIDRequest;
  return proto.external.GetCashFlowByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowByAssetIDRequest}
 */
proto.external.GetCashFlowByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetCashFlowByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCashFlowByAssetIDRequest} returns this
 */
proto.external.GetCashFlowByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetCashFlowByTenantIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetCashFlowByTenantIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetCashFlowByTenantIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByTenantIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowByTenantIDRequest}
 */
proto.external.GetCashFlowByTenantIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowByTenantIDRequest;
  return proto.external.GetCashFlowByTenantIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowByTenantIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowByTenantIDRequest}
 */
proto.external.GetCashFlowByTenantIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowByTenantIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowByTenantIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowByTenantIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByTenantIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetCashFlowByTenantIDRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCashFlowByTenantIDRequest} returns this
 */
proto.external.GetCashFlowByTenantIDRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCashFlowByAssetIDResponse.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetCashFlowByAssetIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetCashFlowByAssetIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByAssetIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    incomeList: jspb.Message.toObjectList(msg.getIncomeList(),
    proto.external.CashFlowReportItem.toObject, includeInstance),
    opexList: jspb.Message.toObjectList(msg.getOpexList(),
    proto.external.CashFlowReportItem.toObject, includeInstance),
    costList: jspb.Message.toObjectList(msg.getCostList(),
    proto.external.CashFlowReportItem.toObject, includeInstance),
    totalList: jspb.Message.toObjectList(msg.getTotalList(),
    proto.external.CashFlowMonthValue.toObject, includeInstance),
    servicechargeList: jspb.Message.toObjectList(msg.getServicechargeList(),
    proto.external.CashFlowReportItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowByAssetIDResponse}
 */
proto.external.GetCashFlowByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowByAssetIDResponse;
  return proto.external.GetCashFlowByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowByAssetIDResponse}
 */
proto.external.GetCashFlowByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.CashFlowReportItem;
      reader.readMessage(value,proto.external.CashFlowReportItem.deserializeBinaryFromReader);
      msg.addIncome(value);
      break;
    case 2:
      var value = new proto.external.CashFlowReportItem;
      reader.readMessage(value,proto.external.CashFlowReportItem.deserializeBinaryFromReader);
      msg.addOpex(value);
      break;
    case 3:
      var value = new proto.external.CashFlowReportItem;
      reader.readMessage(value,proto.external.CashFlowReportItem.deserializeBinaryFromReader);
      msg.addCost(value);
      break;
    case 4:
      var value = new proto.external.CashFlowMonthValue;
      reader.readMessage(value,proto.external.CashFlowMonthValue.deserializeBinaryFromReader);
      msg.addTotal(value);
      break;
    case 5:
      var value = new proto.external.CashFlowReportItem;
      reader.readMessage(value,proto.external.CashFlowReportItem.deserializeBinaryFromReader);
      msg.addServicecharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncomeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowReportItem.serializeBinaryToWriter
    );
  }
  f = message.getOpexList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.CashFlowReportItem.serializeBinaryToWriter
    );
  }
  f = message.getCostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.external.CashFlowReportItem.serializeBinaryToWriter
    );
  }
  f = message.getTotalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.external.CashFlowMonthValue.serializeBinaryToWriter
    );
  }
  f = message.getServicechargeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.external.CashFlowReportItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowReportItem income = 1;
 * @return {!Array<!proto.external.CashFlowReportItem>}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.getIncomeList = function() {
  return /** @type{!Array<!proto.external.CashFlowReportItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowReportItem, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowReportItem>} value
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
*/
proto.external.GetCashFlowByAssetIDResponse.prototype.setIncomeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowReportItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.addIncome = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowReportItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.clearIncomeList = function() {
  return this.setIncomeList([]);
};


/**
 * repeated CashFlowReportItem opex = 2;
 * @return {!Array<!proto.external.CashFlowReportItem>}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.getOpexList = function() {
  return /** @type{!Array<!proto.external.CashFlowReportItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowReportItem, 2));
};


/**
 * @param {!Array<!proto.external.CashFlowReportItem>} value
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
*/
proto.external.GetCashFlowByAssetIDResponse.prototype.setOpexList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.CashFlowReportItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.addOpex = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.CashFlowReportItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.clearOpexList = function() {
  return this.setOpexList([]);
};


/**
 * repeated CashFlowReportItem cost = 3;
 * @return {!Array<!proto.external.CashFlowReportItem>}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.getCostList = function() {
  return /** @type{!Array<!proto.external.CashFlowReportItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowReportItem, 3));
};


/**
 * @param {!Array<!proto.external.CashFlowReportItem>} value
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
*/
proto.external.GetCashFlowByAssetIDResponse.prototype.setCostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.external.CashFlowReportItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.addCost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.external.CashFlowReportItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.clearCostList = function() {
  return this.setCostList([]);
};


/**
 * repeated CashFlowMonthValue total = 4;
 * @return {!Array<!proto.external.CashFlowMonthValue>}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.getTotalList = function() {
  return /** @type{!Array<!proto.external.CashFlowMonthValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowMonthValue, 4));
};


/**
 * @param {!Array<!proto.external.CashFlowMonthValue>} value
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
*/
proto.external.GetCashFlowByAssetIDResponse.prototype.setTotalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.external.CashFlowMonthValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowMonthValue}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.addTotal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.external.CashFlowMonthValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.clearTotalList = function() {
  return this.setTotalList([]);
};


/**
 * repeated CashFlowReportItem serviceCharge = 5;
 * @return {!Array<!proto.external.CashFlowReportItem>}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.getServicechargeList = function() {
  return /** @type{!Array<!proto.external.CashFlowReportItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowReportItem, 5));
};


/**
 * @param {!Array<!proto.external.CashFlowReportItem>} value
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
*/
proto.external.GetCashFlowByAssetIDResponse.prototype.setServicechargeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.external.CashFlowReportItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.addServicecharge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.external.CashFlowReportItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByAssetIDResponse} returns this
 */
proto.external.GetCashFlowByAssetIDResponse.prototype.clearServicechargeList = function() {
  return this.setServicechargeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCashFlowByTenantIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetCashFlowByTenantIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetCashFlowByTenantIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetCashFlowByTenantIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByTenantIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cashflowList: jspb.Message.toObjectList(msg.getCashflowList(),
    proto.external.CashFlow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowByTenantIDResponse}
 */
proto.external.GetCashFlowByTenantIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowByTenantIDResponse;
  return proto.external.GetCashFlowByTenantIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowByTenantIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowByTenantIDResponse}
 */
proto.external.GetCashFlowByTenantIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.CashFlow;
      reader.readMessage(value,proto.external.CashFlow.deserializeBinaryFromReader);
      msg.addCashflow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowByTenantIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowByTenantIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowByTenantIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowByTenantIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashflowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlow cashflow = 1;
 * @return {!Array<!proto.external.CashFlow>}
 */
proto.external.GetCashFlowByTenantIDResponse.prototype.getCashflowList = function() {
  return /** @type{!Array<!proto.external.CashFlow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlow, 1));
};


/**
 * @param {!Array<!proto.external.CashFlow>} value
 * @return {!proto.external.GetCashFlowByTenantIDResponse} returns this
*/
proto.external.GetCashFlowByTenantIDResponse.prototype.setCashflowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlow}
 */
proto.external.GetCashFlowByTenantIDResponse.prototype.addCashflow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowByTenantIDResponse} returns this
 */
proto.external.GetCashFlowByTenantIDResponse.prototype.clearCashflowList = function() {
  return this.setCashflowList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CashFlow.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CashFlow.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CashFlow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CashFlow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlow.toObject = function(includeInstance, msg) {
  var f, obj = {
    metric: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.external.CashFlow.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlow}
 */
proto.external.CashFlow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlow;
  return proto.external.CashFlow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlow}
 */
proto.external.CashFlow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetric(value);
      break;
    case 2:
      var value = new proto.external.CashFlow.Item;
      reader.readMessage(value,proto.external.CashFlow.Item.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.CashFlow.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CashFlow.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CashFlow.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CashFlow.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlow.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlow.Item}
 */
proto.external.CashFlow.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlow.Item;
  return proto.external.CashFlow.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlow.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlow.Item}
 */
proto.external.CashFlow.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlow.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlow.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlow.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlow.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.external.CashFlow.Item.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlow.Item} returns this
 */
proto.external.CashFlow.Item.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.external.CashFlow.Item.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlow.Item} returns this
 */
proto.external.CashFlow.Item.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.external.CashFlow.Item.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlow.Item} returns this
 */
proto.external.CashFlow.Item.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string metric = 1;
 * @return {string}
 */
proto.external.CashFlow.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlow} returns this
 */
proto.external.CashFlow.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Item items = 2;
 * @return {!Array<!proto.external.CashFlow.Item>}
 */
proto.external.CashFlow.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.external.CashFlow.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlow.Item, 2));
};


/**
 * @param {!Array<!proto.external.CashFlow.Item>} value
 * @return {!proto.external.CashFlow} returns this
*/
proto.external.CashFlow.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.CashFlow.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlow.Item}
 */
proto.external.CashFlow.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.CashFlow.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CashFlow} returns this
 */
proto.external.CashFlow.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CashFlowMonthValue.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CashFlowMonthValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CashFlowMonthValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowMonthValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowMonthValue}
 */
proto.external.CashFlowMonthValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowMonthValue;
  return proto.external.CashFlowMonthValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowMonthValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowMonthValue}
 */
proto.external.CashFlowMonthValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowMonthValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowMonthValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowMonthValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowMonthValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.external.CashFlowMonthValue.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowMonthValue} returns this
 */
proto.external.CashFlowMonthValue.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.external.CashFlowMonthValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowMonthValue} returns this
 */
proto.external.CashFlowMonthValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CashFlowReportItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CashFlowReportItem.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CashFlowReportItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CashFlowReportItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowReportItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.external.CashFlowMonthValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.CashFlowReportItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowReportItem;
  return proto.external.CashFlowReportItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowReportItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowReportItem}
 */
proto.external.CashFlowReportItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.external.CashFlowMonthValue;
      reader.readMessage(value,proto.external.CashFlowMonthValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowReportItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowReportItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowReportItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowReportItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.CashFlowMonthValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.CashFlowReportItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlowReportItem} returns this
 */
proto.external.CashFlowReportItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CashFlowMonthValue items = 2;
 * @return {!Array<!proto.external.CashFlowMonthValue>}
 */
proto.external.CashFlowReportItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.external.CashFlowMonthValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowMonthValue, 2));
};


/**
 * @param {!Array<!proto.external.CashFlowMonthValue>} value
 * @return {!proto.external.CashFlowReportItem} returns this
*/
proto.external.CashFlowReportItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.CashFlowMonthValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowMonthValue}
 */
proto.external.CashFlowReportItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.CashFlowMonthValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CashFlowReportItem} returns this
 */
proto.external.CashFlowReportItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AggregateFootfallItem.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AggregateFootfallItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AggregateFootfallItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateFootfallItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    aggregatetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    aggregatetypevalue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    footfallcount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AggregateFootfallItem}
 */
proto.external.AggregateFootfallItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AggregateFootfallItem;
  return proto.external.AggregateFootfallItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AggregateFootfallItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AggregateFootfallItem}
 */
proto.external.AggregateFootfallItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.external.FootfallAggregateType} */ (reader.readEnum());
      msg.setAggregatetype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAggregatetypevalue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFootfallcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AggregateFootfallItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AggregateFootfallItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AggregateFootfallItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateFootfallItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAggregatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAggregatetypevalue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFootfallcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional FootfallAggregateType aggregateType = 1;
 * @return {!proto.external.FootfallAggregateType}
 */
proto.external.AggregateFootfallItem.prototype.getAggregatetype = function() {
  return /** @type {!proto.external.FootfallAggregateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.external.FootfallAggregateType} value
 * @return {!proto.external.AggregateFootfallItem} returns this
 */
proto.external.AggregateFootfallItem.prototype.setAggregatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 aggregateTypeValue = 2;
 * @return {number}
 */
proto.external.AggregateFootfallItem.prototype.getAggregatetypevalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AggregateFootfallItem} returns this
 */
proto.external.AggregateFootfallItem.prototype.setAggregatetypevalue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.external.AggregateFootfallItem.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AggregateFootfallItem} returns this
 */
proto.external.AggregateFootfallItem.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 footfallCount = 4;
 * @return {number}
 */
proto.external.AggregateFootfallItem.prototype.getFootfallcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AggregateFootfallItem} returns this
 */
proto.external.AggregateFootfallItem.prototype.setFootfallcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.AggregateFootfallResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AggregateFootfallResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AggregateFootfallResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AggregateFootfallResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateFootfallResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    proto.external.BarChartSeriesItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AggregateFootfallResponse}
 */
proto.external.AggregateFootfallResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AggregateFootfallResponse;
  return proto.external.AggregateFootfallResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AggregateFootfallResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AggregateFootfallResponse}
 */
proto.external.AggregateFootfallResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 2:
      var value = new proto.external.BarChartSeriesItem;
      reader.readMessage(value,proto.external.BarChartSeriesItem.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AggregateFootfallResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AggregateFootfallResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AggregateFootfallResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateFootfallResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.BarChartSeriesItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string categories = 1;
 * @return {!Array<string>}
 */
proto.external.AggregateFootfallResponse.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.AggregateFootfallResponse} returns this
 */
proto.external.AggregateFootfallResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.AggregateFootfallResponse} returns this
 */
proto.external.AggregateFootfallResponse.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AggregateFootfallResponse} returns this
 */
proto.external.AggregateFootfallResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated BarChartSeriesItem series = 2;
 * @return {!Array<!proto.external.BarChartSeriesItem>}
 */
proto.external.AggregateFootfallResponse.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.external.BarChartSeriesItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.BarChartSeriesItem, 2));
};


/**
 * @param {!Array<!proto.external.BarChartSeriesItem>} value
 * @return {!proto.external.AggregateFootfallResponse} returns this
*/
proto.external.AggregateFootfallResponse.prototype.setSeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.BarChartSeriesItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.BarChartSeriesItem}
 */
proto.external.AggregateFootfallResponse.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.BarChartSeriesItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AggregateFootfallResponse} returns this
 */
proto.external.AggregateFootfallResponse.prototype.clearSeriesList = function() {
  return this.setSeriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.BarChartSeriesItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.BarChartSeriesItem.prototype.toObject = function(opt_includeInstance) {
  return proto.external.BarChartSeriesItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.BarChartSeriesItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BarChartSeriesItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriestitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.BarChartSeriesItem}
 */
proto.external.BarChartSeriesItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.BarChartSeriesItem;
  return proto.external.BarChartSeriesItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.BarChartSeriesItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.BarChartSeriesItem}
 */
proto.external.BarChartSeriesItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriestitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.BarChartSeriesItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.BarChartSeriesItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.BarChartSeriesItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BarChartSeriesItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriestitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string seriesTitle = 1;
 * @return {string}
 */
proto.external.BarChartSeriesItem.prototype.getSeriestitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.BarChartSeriesItem} returns this
 */
proto.external.BarChartSeriesItem.prototype.setSeriestitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.external.BarChartSeriesItem.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.BarChartSeriesItem} returns this
 */
proto.external.BarChartSeriesItem.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.BarChartSeriesItem} returns this
 */
proto.external.BarChartSeriesItem.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.BarChartSeriesItem} returns this
 */
proto.external.BarChartSeriesItem.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAggregateFootfallsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAggregateFootfallsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAggregateFootfallsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAggregateFootfallsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aggregatetype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAggregateFootfallsRequest}
 */
proto.external.GetAggregateFootfallsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAggregateFootfallsRequest;
  return proto.external.GetAggregateFootfallsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAggregateFootfallsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAggregateFootfallsRequest}
 */
proto.external.GetAggregateFootfallsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 2:
      var value = /** @type {!proto.external.FootfallAggregateType} */ (reader.readEnum());
      msg.setAggregatetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAggregateFootfallsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAggregateFootfallsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAggregateFootfallsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAggregateFootfallsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAggregatetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetAggregateFootfallsRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetAggregateFootfallsRequest} returns this
 */
proto.external.GetAggregateFootfallsRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FootfallAggregateType aggregateType = 2;
 * @return {!proto.external.FootfallAggregateType}
 */
proto.external.GetAggregateFootfallsRequest.prototype.getAggregatetype = function() {
  return /** @type {!proto.external.FootfallAggregateType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.external.FootfallAggregateType} value
 * @return {!proto.external.GetAggregateFootfallsRequest} returns this
 */
proto.external.GetAggregateFootfallsRequest.prototype.setAggregatetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AggregateTurnoverItem.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AggregateTurnoverItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AggregateTurnoverItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateTurnoverItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    yearmonth: jspb.Message.getFieldWithDefault(msg, 1, ""),
    turnovertotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AggregateTurnoverItem}
 */
proto.external.AggregateTurnoverItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AggregateTurnoverItem;
  return proto.external.AggregateTurnoverItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AggregateTurnoverItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AggregateTurnoverItem}
 */
proto.external.AggregateTurnoverItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYearmonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnovertotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AggregateTurnoverItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AggregateTurnoverItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AggregateTurnoverItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateTurnoverItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYearmonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTurnovertotal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string yearMonth = 1;
 * @return {string}
 */
proto.external.AggregateTurnoverItem.prototype.getYearmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AggregateTurnoverItem} returns this
 */
proto.external.AggregateTurnoverItem.prototype.setYearmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double turnoverTotal = 2;
 * @return {number}
 */
proto.external.AggregateTurnoverItem.prototype.getTurnovertotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AggregateTurnoverItem} returns this
 */
proto.external.AggregateTurnoverItem.prototype.setTurnovertotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.AggregateTurnoverResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AggregateTurnoverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AggregateTurnoverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AggregateTurnoverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateTurnoverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    proto.external.BarChartSeriesItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AggregateTurnoverResponse}
 */
proto.external.AggregateTurnoverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AggregateTurnoverResponse;
  return proto.external.AggregateTurnoverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AggregateTurnoverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AggregateTurnoverResponse}
 */
proto.external.AggregateTurnoverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 2:
      var value = new proto.external.BarChartSeriesItem;
      reader.readMessage(value,proto.external.BarChartSeriesItem.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AggregateTurnoverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AggregateTurnoverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AggregateTurnoverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AggregateTurnoverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.BarChartSeriesItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string categories = 1;
 * @return {!Array<string>}
 */
proto.external.AggregateTurnoverResponse.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.AggregateTurnoverResponse} returns this
 */
proto.external.AggregateTurnoverResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.AggregateTurnoverResponse} returns this
 */
proto.external.AggregateTurnoverResponse.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AggregateTurnoverResponse} returns this
 */
proto.external.AggregateTurnoverResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated BarChartSeriesItem series = 2;
 * @return {!Array<!proto.external.BarChartSeriesItem>}
 */
proto.external.AggregateTurnoverResponse.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.external.BarChartSeriesItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.BarChartSeriesItem, 2));
};


/**
 * @param {!Array<!proto.external.BarChartSeriesItem>} value
 * @return {!proto.external.AggregateTurnoverResponse} returns this
*/
proto.external.AggregateTurnoverResponse.prototype.setSeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.BarChartSeriesItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.BarChartSeriesItem}
 */
proto.external.AggregateTurnoverResponse.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.BarChartSeriesItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AggregateTurnoverResponse} returns this
 */
proto.external.AggregateTurnoverResponse.prototype.clearSeriesList = function() {
  return this.setSeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAggregateTurnoversRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAggregateTurnoversRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAggregateTurnoversRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAggregateTurnoversRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantid: (f = msg.getTenantid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAggregateTurnoversRequest}
 */
proto.external.GetAggregateTurnoversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAggregateTurnoversRequest;
  return proto.external.GetAggregateTurnoversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAggregateTurnoversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAggregateTurnoversRequest}
 */
proto.external.GetAggregateTurnoversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAggregateTurnoversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAggregateTurnoversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAggregateTurnoversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAggregateTurnoversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetAggregateTurnoversRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetAggregateTurnoversRequest} returns this
 */
proto.external.GetAggregateTurnoversRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue tenantID = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.GetAggregateTurnoversRequest.prototype.getTenantid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.GetAggregateTurnoversRequest} returns this
*/
proto.external.GetAggregateTurnoversRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.GetAggregateTurnoversRequest} returns this
 */
proto.external.GetAggregateTurnoversRequest.prototype.clearTenantid = function() {
  return this.setTenantid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.GetAggregateTurnoversRequest.prototype.hasTenantid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TenantPerformanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TenantPerformanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TenantPerformanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantPerformanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baserent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    servicecharge: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    turnoverrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    turnover: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    other: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalincome: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    tenantincentives: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    leasingcosts: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    allocatedopex: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    effortratio: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    salesdensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    netprofitability: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    tenantcosts: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    rentdiscount: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantPerformanceResponse}
 */
proto.external.TenantPerformanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantPerformanceResponse;
  return proto.external.TenantPerformanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantPerformanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantPerformanceResponse}
 */
proto.external.TenantPerformanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaserent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServicecharge(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverrent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnover(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOther(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalincome(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTenantincentives(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeasingcosts(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAllocatedopex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEffortratio(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSalesdensity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNetprofitability(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTenantcosts(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRentdiscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantPerformanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantPerformanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantPerformanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantPerformanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaserent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getServicecharge();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTurnoverrent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTurnover();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getOther();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalincome();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTenantincentives();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLeasingcosts();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAllocatedopex();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getEffortratio();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSalesdensity();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getNetprofitability();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTenantcosts();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getRentdiscount();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.TenantPerformanceResponse.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double baseRent = 2;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getBaserent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setBaserent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double serviceCharge = 3;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getServicecharge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setServicecharge = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double turnoverRent = 4;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getTurnoverrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTurnoverrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double turnover = 5;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double other = 6;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getOther = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setOther = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double totalIncome = 7;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getTotalincome = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTotalincome = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double tenantIncentives = 8;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getTenantincentives = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTenantincentives = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double leasingCosts = 9;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getLeasingcosts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setLeasingcosts = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double allocatedOpex = 10;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getAllocatedopex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setAllocatedopex = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double effortRatio = 11;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getEffortratio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setEffortratio = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double salesDensity = 12;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getSalesdensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setSalesdensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double netProfitability = 13;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getNetprofitability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setNetprofitability = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double tenantCosts = 14;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getTenantcosts = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setTenantcosts = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double rentDiscount = 15;
 * @return {number}
 */
proto.external.TenantPerformanceResponse.prototype.getRentdiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantPerformanceResponse} returns this
 */
proto.external.TenantPerformanceResponse.prototype.setRentdiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TenantPerformanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TenantPerformanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TenantPerformanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantPerformanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantPerformanceRequest}
 */
proto.external.TenantPerformanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantPerformanceRequest;
  return proto.external.TenantPerformanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantPerformanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantPerformanceRequest}
 */
proto.external.TenantPerformanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantPerformanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantPerformanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantPerformanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantPerformanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.TenantPerformanceRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantPerformanceRequest} returns this
 */
proto.external.TenantPerformanceRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetPerformanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetPerformanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetPerformanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetPerformanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetPerformanceRequest}
 */
proto.external.AssetPerformanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetPerformanceRequest;
  return proto.external.AssetPerformanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetPerformanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetPerformanceRequest}
 */
proto.external.AssetPerformanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetPerformanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetPerformanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetPerformanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetPerformanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.AssetPerformanceRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetPerformanceRequest} returns this
 */
proto.external.AssetPerformanceRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AssetPerformanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AssetPerformanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AssetPerformanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetPerformanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    baserent: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    turnoverrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    otherincome: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    servicecharge: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    opexchange: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    yearstartnoi: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    yearendnoi: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetPerformanceResponse}
 */
proto.external.AssetPerformanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetPerformanceResponse;
  return proto.external.AssetPerformanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetPerformanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetPerformanceResponse}
 */
proto.external.AssetPerformanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaserent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnoverrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOtherincome(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServicecharge(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpexchange(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setYearstartnoi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setYearendnoi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetPerformanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetPerformanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetPerformanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetPerformanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaserent();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTurnoverrent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOtherincome();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getServicecharge();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOpexchange();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getYearstartnoi();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getYearendnoi();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double baseRent = 1;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getBaserent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setBaserent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double turnoverRent = 2;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getTurnoverrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setTurnoverrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double otherIncome = 3;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getOtherincome = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setOtherincome = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double serviceCharge = 4;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getServicecharge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setServicecharge = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double opexChange = 5;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getOpexchange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setOpexchange = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double yearStartNOI = 6;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getYearstartnoi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setYearstartnoi = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double yearEndNOI = 7;
 * @return {number}
 */
proto.external.AssetPerformanceResponse.prototype.getYearendnoi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AssetPerformanceResponse} returns this
 */
proto.external.AssetPerformanceResponse.prototype.setYearendnoi = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.SummaryTableRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.SummaryTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.SummaryTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.SummaryTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SummaryTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.SummaryTableRequest}
 */
proto.external.SummaryTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.SummaryTableRequest;
  return proto.external.SummaryTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.SummaryTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.SummaryTableRequest}
 */
proto.external.SummaryTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.SummaryTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.SummaryTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.SummaryTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SummaryTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.SummaryTableRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.SummaryTableRequest} returns this
*/
proto.external.SummaryTableRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.SummaryTableRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableRequest} returns this
 */
proto.external.SummaryTableRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.SummaryTableResponse.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.SummaryTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.SummaryTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.SummaryTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SummaryTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractedList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    mlasList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    totalforecastsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    actualsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 4)) == null ? undefined : f,
    deltasList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.SummaryTableResponse}
 */
proto.external.SummaryTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.SummaryTableResponse;
  return proto.external.SummaryTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.SummaryTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.SummaryTableResponse}
 */
proto.external.SummaryTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContracted(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMlas(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTotalforecasts(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActuals(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeltas(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.SummaryTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.SummaryTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.SummaryTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SummaryTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractedList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getMlasList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getTotalforecastsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getActualsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      4,
      f
    );
  }
  f = message.getDeltasList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
};


/**
 * repeated double contracted = 1;
 * @return {!Array<number>}
 */
proto.external.SummaryTableResponse.prototype.getContractedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.setContractedList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.addContracted = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.clearContractedList = function() {
  return this.setContractedList([]);
};


/**
 * repeated double mlas = 2;
 * @return {!Array<number>}
 */
proto.external.SummaryTableResponse.prototype.getMlasList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.setMlasList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.addMlas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.clearMlasList = function() {
  return this.setMlasList([]);
};


/**
 * repeated double totalForecasts = 3;
 * @return {!Array<number>}
 */
proto.external.SummaryTableResponse.prototype.getTotalforecastsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.setTotalforecastsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.addTotalforecasts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.clearTotalforecastsList = function() {
  return this.setTotalforecastsList([]);
};


/**
 * repeated double actuals = 4;
 * @return {!Array<number>}
 */
proto.external.SummaryTableResponse.prototype.getActualsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.setActualsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.addActuals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.clearActualsList = function() {
  return this.setActualsList([]);
};


/**
 * repeated double deltas = 5;
 * @return {!Array<number>}
 */
proto.external.SummaryTableResponse.prototype.getDeltasList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.setDeltasList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.addDeltas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SummaryTableResponse} returns this
 */
proto.external.SummaryTableResponse.prototype.clearDeltasList = function() {
  return this.setDeltasList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ForecastsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ForecastsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ForecastsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ForecastsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ForecastsRequest}
 */
proto.external.ForecastsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ForecastsRequest;
  return proto.external.ForecastsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ForecastsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ForecastsRequest}
 */
proto.external.ForecastsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ForecastsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ForecastsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ForecastsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.ForecastsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.ForecastsRequest} returns this
*/
proto.external.ForecastsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.ForecastsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ForecastsRequest} returns this
 */
proto.external.ForecastsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ForecastsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ForecastsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ForecastsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ForecastsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    forecastsList: jspb.Message.toObjectList(msg.getForecastsList(),
    proto.external.ForecastRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ForecastsResponse}
 */
proto.external.ForecastsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ForecastsResponse;
  return proto.external.ForecastsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ForecastsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ForecastsResponse}
 */
proto.external.ForecastsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ForecastRow;
      reader.readMessage(value,proto.external.ForecastRow.deserializeBinaryFromReader);
      msg.addForecasts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ForecastsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ForecastsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ForecastsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.ForecastRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForecastRow forecasts = 1;
 * @return {!Array<!proto.external.ForecastRow>}
 */
proto.external.ForecastsResponse.prototype.getForecastsList = function() {
  return /** @type{!Array<!proto.external.ForecastRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ForecastRow, 1));
};


/**
 * @param {!Array<!proto.external.ForecastRow>} value
 * @return {!proto.external.ForecastsResponse} returns this
*/
proto.external.ForecastsResponse.prototype.setForecastsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.ForecastRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ForecastRow}
 */
proto.external.ForecastsResponse.prototype.addForecasts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.ForecastRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ForecastsResponse} returns this
 */
proto.external.ForecastsResponse.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ForecastRow.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ForecastRow.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ForecastRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ForecastRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totals: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    forecastsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    managerid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ForecastRow}
 */
proto.external.ForecastRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ForecastRow;
  return proto.external.ForecastRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ForecastRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ForecastRow}
 */
proto.external.ForecastRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotals(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addForecasts(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ForecastRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ForecastRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ForecastRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ForecastRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotals();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getForecastsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getManagerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.ForecastRow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totals = 2;
 * @return {number}
 */
proto.external.ForecastRow.prototype.getTotals = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.setTotals = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated double forecasts = 3;
 * @return {!Array<number>}
 */
proto.external.ForecastRow.prototype.getForecastsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.setForecastsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.addForecasts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.clearForecastsList = function() {
  return this.setForecastsList([]);
};


/**
 * optional string managerID = 4;
 * @return {string}
 */
proto.external.ForecastRow.prototype.getManagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ForecastRow} returns this
 */
proto.external.ForecastRow.prototype.setManagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ActualsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ActualsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ActualsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ActualsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ActualsRequest}
 */
proto.external.ActualsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ActualsRequest;
  return proto.external.ActualsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ActualsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ActualsRequest}
 */
proto.external.ActualsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ActualsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ActualsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ActualsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.ActualsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.ActualsRequest} returns this
*/
proto.external.ActualsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.ActualsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ActualsRequest} returns this
 */
proto.external.ActualsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ActualsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ActualsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ActualsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ActualsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    actualsList: jspb.Message.toObjectList(msg.getActualsList(),
    proto.external.ActualRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ActualsResponse}
 */
proto.external.ActualsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ActualsResponse;
  return proto.external.ActualsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ActualsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ActualsResponse}
 */
proto.external.ActualsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ActualRow;
      reader.readMessage(value,proto.external.ActualRow.deserializeBinaryFromReader);
      msg.addActuals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ActualsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ActualsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ActualsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActualsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.ActualRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActualRow actuals = 1;
 * @return {!Array<!proto.external.ActualRow>}
 */
proto.external.ActualsResponse.prototype.getActualsList = function() {
  return /** @type{!Array<!proto.external.ActualRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ActualRow, 1));
};


/**
 * @param {!Array<!proto.external.ActualRow>} value
 * @return {!proto.external.ActualsResponse} returns this
*/
proto.external.ActualsResponse.prototype.setActualsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.ActualRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ActualRow}
 */
proto.external.ActualsResponse.prototype.addActuals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.ActualRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ActualsResponse} returns this
 */
proto.external.ActualsResponse.prototype.clearActualsList = function() {
  return this.setActualsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ActualRow.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ActualRow.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ActualRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ActualRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totals: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    actualsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    managerid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ActualRow}
 */
proto.external.ActualRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ActualRow;
  return proto.external.ActualRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ActualRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ActualRow}
 */
proto.external.ActualRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotals(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActuals(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ActualRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ActualRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ActualRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ActualRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotals();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getActualsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getManagerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.ActualRow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double totals = 2;
 * @return {number}
 */
proto.external.ActualRow.prototype.getTotals = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.setTotals = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated double actuals = 3;
 * @return {!Array<number>}
 */
proto.external.ActualRow.prototype.getActualsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.setActualsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.addActuals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.clearActualsList = function() {
  return this.setActualsList([]);
};


/**
 * optional string managerID = 4;
 * @return {string}
 */
proto.external.ActualRow.prototype.getManagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ActualRow} returns this
 */
proto.external.ActualRow.prototype.setManagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.external.FootfallAggregateType = {
  UNKNOWN_AGGREGATE_TYPE: 0,
  WEEKDAY: 1,
  MONTH: 2,
  YEAR: 3
};

goog.object.extend(exports, proto.external);
