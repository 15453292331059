/* eslint-disable no-plusplus */
import React from 'react';

import { Box, DialogContent, DialogActions, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import { startOfMonth, getDaysInMonth, format, addDays, addMonths, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

import FootFallList from './FootFallList';

const AddFooftall = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.assets.footfall.loading);
  const loaded = useSelector((state) => state.assets.footfall.loaded);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);
  const [monthYear, setMonthYear] = React.useState(format(new Date(), 'MMM yyyy'));
  const footfall = useSelector((state) => state.assets.footfall.footfall);
  const [data, setData] = React.useState([]);

  function handleMonthChange(add) {
    const monthYearCopy = !add
      ? addMonths(new Date(monthYear), 1)
      : subDays(new Date(monthYear), 1);

    const formatedNewMonthYear = format(monthYearCopy, 'MMM yyyy');

    setMonthYear(formatedNewMonthYear);
  }

  React.useEffect(() => {
    dispatch(actions.getFootfall(asset.id, format(new Date(monthYear), 'yyyy-MM')));
  }, [monthYear]);

  React.useEffect(() => {
    if (!loaded) {
      return;
    }

    const startOfTheMonth = startOfMonth(new Date(monthYear));
    const getDaysInTheMonth = getDaysInMonth(new Date(monthYear));
    const array = [];
    let footfallItem = {};

    for (let i = 0; i < getDaysInTheMonth; i++) {
      if (footfall.length > 0) {
        footfallItem = footfall.find(
          (ftf) => ftf.date === format(addDays(startOfTheMonth, i), 'yyyy-MM-dd')
        );
      }

      array.push({
        name: addDays(startOfTheMonth, i),
        footfall: footfallItem?.footfallcount ? footfallItem.footfallcount : '',
        id: footfallItem?.id ? footfallItem.id : ''
      });
    }

    setData(array);
  }, [footfall]);

  const handleClose = () => {
    dispatch(actions.getAssetKpi(asset.id));
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="lg" header="Footfall" xl={false}>
      <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} m={2}>
          <ArrowBackIosIcon onClick={() => handleMonthChange(true)} className={classes.arrow} />
          <Typography className={classes.footfallHeader}>{monthYear}</Typography>

          <ArrowForwardIosIcon onClick={() => handleMonthChange()} className={classes.arrow} />
        </Box>
        <Box height={600} overflow="auto" borderTop="1px solid #D9D9D9">
          {loading ? (
            <Spinner />
          ) : (
            <FootFallList
              data={data}
              handleSave={() =>
                dispatch(actions.getFootfall(asset.id, format(new Date(monthYear), 'yyyy-MM')))
              }
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <CancelButton onClick={() => dispatch(closeModal())} />
          <SaveButton onClick={handleClose} label="Finish" />
        </Box>
      </DialogActions>
    </ModalWrapper>
  );
};

export default React.memo(AddFooftall);
