import React, { useMemo, useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import { rentrollStorage, ref, uploadBytesResumable } from 'config/firebase';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import { runAntivirusScan } from 'store/actions/actualAction';

import {
  baseStyle,
  focusedStyle,
  acceptStyle,
  rejectStyle,
  thumb,
  thumbInner,
  thumbsContainer,
  img
} from './style';

const UploadImages = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { query } = useSelector((state) => state.modals.modalProps);
  const { images } = useSelector((state) => state.assets.assetImages);
  const dispatch = useDispatch();
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 6,
    multiple: true,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(
          (file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          // eslint-disable-next-line function-paren-newline
        )
      );
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleFileUpload = async () => {
    setLoading(true);
    const { infected, error } = await runAntivirusScan(files);

    if (error) {
      toast.error(error);
      return;
    }

    if (infected) {
      toast.error('File infected!');
      return;
    }

    if (!infected) {
      const promises = files.map((file) => {
        const storageRef = ref(rentrollStorage, `${query.id}/${file.name}`);
        return uploadBytesResumable(storageRef, file);
      });

      await Promise.all(promises)
        .then(() => {
          toast.success('Images uploaded successfully!');
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setLoading(false);
          dispatch(actions.listImages(query.id));
          dispatch(closeModal());
        });
    }
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt=""
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => () => files.forEach((file) => URL.revokeObjectURL(file.preview)), []);

  return (
    <ModalWrapper
      size="xl"
      header="Upload Images"
      handleSave={handleFileUpload}
      disabled={!files?.length || images?.length + files?.length > 6}
      loading={loading}
      label="Upload"
    >
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag and drop some files here, or click to select files (Up to 6 images)</p>
          <em>(Only *.png, *.jpg and *.jpeg images will be accepted)</em>
        </div>
        <aside style={thumbsContainer}>{images?.length + files?.length <= 6 && thumbs}</aside>
        {images?.length + files?.length > 6 && (
          <Typography color="error" variant="heading2">
            Too many files! You can have up to 6 files uploaded.
          </Typography>
        )}
      </div>
    </ModalWrapper>
  );
};

export default UploadImages;
