import {getEnviroment} from 'config/config';

import {AlertStreamServiceClient} from '../../api/alert_grpc_web_pb';
import {AssetServiceClient} from '../../api/asset_grpc_web_pb';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const AlertServiceClient = new AlertStreamServiceClient(getEnviroment(env));
export const AssetService = new AssetServiceClient(getEnviroment(env));

enableDevTools([
    AlertServiceClient,
    AssetService
]);