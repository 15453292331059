import React, { useState } from 'react';

import { Dialog, Box, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fonts.base,
    fontSize: 20,
    color: theme.palette.common.white
  },
  box: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 26,
    cursor: 'pointer'
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F'
  },
  textField: {
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  input: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  responsive: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      minWidth: 700
    }
  },
  confirm: {
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  }
}));

const SupplierDetailsEdit = ({ open, handleClose, details, loading, handleSave }) => {
  const classes = useStyles();

  const [form, setForm] = useState(details);

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-supplier-details"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.title}>Supplier Details</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          className={classes.textField}
          value={form.companyname}
          disabled={loading}
          onChange={(e) => handleChange('companyname', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <Box display="flex" justifyContent="space-between" className={classes.box} pt={2}>
          <TextField
            label="Country"
            variant="outlined"
            className={classes.textField}
            value={form.country}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('country', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
          <TextField
            label="City"
            variant="outlined"
            className={classes.textField}
            value={form.city}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('city', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" className={classes.box} pt={2} pb={2}>
          <TextField
            label="Street"
            variant="outlined"
            className={classes.textField}
            value={form.street}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('street', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
          <TextField
            label="Post Code"
            variant="outlined"
            className={classes.textField}
            value={form.postcode}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('postcode', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
        </Box>
        <TextField
          fullWidth
          label="Point of contact"
          variant="outlined"
          className={classes.textField}
          value={form.contact}
          disabled={loading}
          onChange={(e) => handleChange('contact', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />

        <Box display="flex" justifyContent="space-between" className={classes.box} pt={2}>
          <TextField
            label="Email"
            variant="outlined"
            className={classes.textField}
            value={form.email}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('email', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
          <TextField
            label="Telephone"
            variant="outlined"
            className={classes.textField}
            value={form.phone}
            disabled={loading}
            style={{ width: '48%' }}
            onChange={(e) => handleChange('phone', e.target.value)}
            slotProps={{
              input: {
                classes: {
                  input: classes.input
                }
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} className={classes.confirm}>
          <CancelButton onClick={handleClose} />
          <SaveButton onClick={() => handleSave(form)} loading={loading} />
        </Box>
      </DialogActions>
    </Dialog>)
  );
};

export default SupplierDetailsEdit;

SupplierDetailsEdit.defaultProps = {
  loading: false
};

SupplierDetailsEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  handleSave: PropTypes.func.isRequired
};
