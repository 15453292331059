import React, { useCallback } from 'react';

import { Box, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    '& .MuiFormControl-root .MuiOutlinedInput-input': {
      fontFamily: 'Open Sans !important',
      color: '#5C5C5C'
    }
  },
  datePker: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  }
}));

const FORMAT = 'dd/MM/yyyy';
const KeyBoardComponentPicker = ({
  selectedDate,
  setSelectedDate,
  label,
  minDate,
  maxDate,
  maxErrorText,
  minErrorText,
  small
}) => {
  const classes = useStyles();

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);
  return (
    <Box className={classes.root} style={{ width: small ? 230 : 400 }}>
      <DatePicker
        variant="inline"
        minDate={minDate}
        maxDate={maxDate}
        format={FORMAT}
        placeholder="dd/mm/yyyy"
        margin="normal"
        inputVariant="outlined"
        defaultValue={new Date()}
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        autoOk
        fullWidth
        style={{ marginTop: 0 }}
        className={classes.datePker}
        maxDateMessage={maxErrorText}
        minDateMessage={minErrorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon style={{ color: '#9D9D9D' }} />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default React.memo(KeyBoardComponentPicker);

KeyBoardComponentPicker.defaultProps = {
  minDate: new Date('02/02/1989'),
  maxDate: new Date('02/02/2050'),
  selectedDate: '',
  maxErrorText: 'Date Cannot be after end date',
  minErrorText: 'Date Cannot be before start date',
  small: false
};

KeyBoardComponentPicker.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  setSelectedDate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxErrorText: PropTypes.string,
  minErrorText: PropTypes.string,
  small: PropTypes.bool
};
