import React, {useMemo} from 'react';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import DetailsIcon from '@mui/icons-material/Details';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GroupIcon from '@mui/icons-material/Group';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CustomIcon from 'components/CustomIcon';
import useRouter from 'hooks/useRouter';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(2),
        color: theme.palette.text.grey,
        fontFamily: theme.fonts.openSans,
        padding: theme.spacing(1)
    },
    details: {
        fontSize: 16,
        color: '#FFF',
        fontFamily: theme.fonts.openSans,
        padding: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8'
        }
    },
    drawerPaper: {
        'overflow-y': 'auto',
        top: 'unset',
        backgroundColor: '#1B2740',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }
        }
    },
    light: {
        backgroundColor: '#555'
    },
    white: {
        color: theme.palette.common.white
    },
    paddingBottom: {
        paddingBottom: '65px !important'
    },
    itemIcon: {
        minWidth: 50,
        marginLeft: 12
    }
}));

const SideMenu = () => {
    const classes = useStyles();
    const router = useRouter();
    const {id} = router.query;
    const {tab} = router.match.params;
    const {data: asset} = useSelector((state) => state.assets.assetDetails);

    const items = useMemo(
        () => [
            {to: `/Asset/${id}/BaseRent`, label: 'Base Rent', active: 'BaseRent'},
            {
                to: `/Asset/${id}/TurnoverRent`,
                label: 'Turnover Rent',
                active: 'TurnoverRent'
            },
            {
                to: `/Asset/${id}/ServiceCharge`,
                label: 'Service Charge',
                active: 'ServiceCharge'
            },
            {
                to: `/Asset/${id}/OtherIncome`,
                label: 'Other Income',
                active: 'OtherIncome'
            },
            {
                to: `/Asset/${id}/CumulativeRent`,
                label: 'Cumulative Rent',
                active: 'CumulativeRent'
            },
            {
                to: `/Asset/${id}/NOI`,
                label: 'Net Operating Income',
                active: 'NOI'
            },
            {
                to: `/Asset/${id}/LeasingFee`,
                label: 'Leasing Fee',
                active: 'LeasingFee'
            },
            {
                to: `/Asset/${id}/FitOut`,
                label: 'Fit Out',
                active: 'FitOut'
            },
            {to: `/Asset/${id}/Opex`, label: 'Opex', active: 'Opex'},
            {to: `/Asset/${id}/Capex`, label: 'Capex', active: 'Capex'},
            {

                to: `/Asset/${id}/OtherExpenses`,
                label: 'Other Expenses',
                active: 'OtherExpenses'
            }
        ],
        [id]
    );

    const [open, setOpen] = React.useState(items.includes(tab));
    const handleClick = () => {
        setOpen(!open);
    };
    const closeReports = () => {
        setOpen(false);
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                paper: classes.drawerPaper
            }}
            style={{height: '100%'}}
        >
            <List>
                <ListItem
                    className={classes.details}
                    selected={tab === 'Details'}
                    component={NavLink}
                    to={`/Asset/${id}/Details`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <DetailsIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Site Plan</ListItemText>
                </ListItem>
                <ListItem
                    className={classes.details}
                    selected={tab === 'Tenants'}
                    component={NavLink}
                    to={`/Asset/${id}/Tenants`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <GroupIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Tenants</ListItemText>
                </ListItem>
                <ListItem
                    className={classes.details}
                    selected={tab === 'OpexManager'}
                    component={NavLink}
                    to={`/Asset/${id}/OpexManager`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <InsertChartIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Opex Manager</ListItemText>
                </ListItem>
                <ListItem
                    className={classes.details}
                    selected={tab === 'CostManager'}
                    component={NavLink}
                    to={`/Asset/${id}/CostManager`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <InsertChartIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Capex Manager</ListItemText>
                </ListItem>
                <ListItem
                    className={classes.details}
                    selected={tab === 'Turnover'}
                    component={NavLink}
                    to={`/Asset/${id}/Turnover`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <FolderOpenIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Turnover</ListItemText>
                </ListItem>
                <ListItem
                    className={classes.details}
                    selected={tab === 'Actuals'}
                    component={NavLink}
                    to={`/Asset/${id}/Actuals`}
                    onClick={closeReports}
                >
                    <ListItemIcon className={classes.itemIcon}>
                        <FolderOpenIcon className={classes.white}/>
                    </ListItemIcon>
                    <ListItemText>Actuals</ListItemText>
                </ListItem>
            <ListItem
                className={classes.details}
                selected={tab === 'Valuations'}
                component={NavLink}
                to={`/Asset/${id}/Valuations`}
                onClick={closeReports}
            >
                <ListItemIcon className={classes.itemIcon}>
                    <ShowChartIcon className={classes.white}/>
                </ListItemIcon>
                <ListItemText>Valuations</ListItemText>
            </ListItem>
            <ListItem
                className={classes.details}
                onClick={handleClick}
            >
                <ListItemIcon className={classes.itemIcon}>
                    <CustomIcon name="link_icon" width="24" height="24"/>
                </ListItemIcon>
                <ListItemText>Reports</ListItemText>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            </List>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {items.map((item) => (
                        <ListItem
                            className={classes.details}
                            component={NavLink}
                            to={item.to}
                            selected={tab === item.active}
                        >
                            <ListItemText primary={item.label} style={{"margin-left": "30%"}}></ListItemText>
                        </ListItem>
                    ))}
                    <ListItem
                        className={classes.details}
                        component={NavLink}
                        to={{
                            pathname: `/Asset/${id}/AdvancedReporting`,
                            state: {from: {text: asset.name, href: `/Asset/${id}/Details`}}
                        }}
                    >
                        <ListItemText style={{"margin-left": "30%"}}>Custom</ListItemText>
                    </ListItem>
                    <div style={{height: '100px'}}></div>
                </List>
            </Collapse>
        </Drawer>
    );
};

export default SideMenu;
