import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApexChart from 'components/Charts';
import {numberWithCommas} from 'helpers/utils';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {config} from './chartConfig';
import useStyles from './styles';

const GraphCard = ({dataCy, to, from, totalValue, prevTotalValue, series, name, period, annualData, loading}) => {
    const classes = useStyles();
    const [options, setOptions] = useState({...config.options});

    useEffect(() => {
        if (period === 'year') {
            const newOptions = {
                ...config.options,
                xaxis: {
                    ...config.options.xaxis,
                    categories: new Array(annualData.length).fill().map((_, i) => annualData.year + i)
                }
            };
            setOptions(newOptions);
        } else {
            const newOptions = {
                ...config.options,
                xaxis: {
                    ...config.options.xaxis,
                    categories: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec'
                    ]
                }
            };
            setOptions(newOptions);
        }
    }, [period, annualData]);

    return (
        <Box data-cy={dataCy}>
            <Paper className={classes.paper}>
                <Box style={{padding: '10px 20px'}}>
                    <NavLink
                        to={{
                            pathname: to,
                            search: from
                        }}
                        className={classes.navLink}
                    >
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1" style={{color: '#555'}} className={classes.chartName}>
                                {name}
                            </Typography>
                            <MoreVertIcon style={{color: '#666'}}/>
                        </Box>
                    </NavLink>
                    <Box className={classes.wrapCard}>
                        <Box className={classes.wrapHeading}>
                            <Typography variant="h6">{`€${numberWithCommas(totalValue)}`}</Typography>
                            <Typography className={classes.fromText}>
                                {`from €${numberWithCommas(prevTotalValue)}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <ApexChart options={options} series={series}/>
            </Paper>
        </Box>
    );
};

export default GraphCard;

GraphCard.defaultProps = {
    totalValue: 0,
    prevTotalValue: 0,
    name: '',
    series: [],
    dataCy: ''
};

GraphCard.propTypes = {
    totalValue: PropTypes.number,
    prevTotalValue: PropTypes.number,
    name: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.object),
    dataCy: PropTypes.string,
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired
};
