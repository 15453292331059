import {DataIngestionServiceClient} from 'api/data_ingestion_grpc_web_pb';
import {getEnviroment} from 'config/config';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

export const DataIngestionService = new DataIngestionServiceClient(getEnviroment(env));

enableDevTools([
    DataIngestionService
]);
