import React from 'react';

import { Tabs as MuiTabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import useStyles from 'styles/costManager';

const Tabs = ({ value, handleChange, tabNames }) => {
  const classes = useStyles();
  return (
    <MuiTabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="on"
      classes={{
        indicator: classes.bigIndicator,
        root: classes.tabsContainer,
        scroller: classes.scroller
      }}
      className={classes.tabsContainer}
    >
      {tabNames.map((tabName) => (
        <Tab
          key={tabName}
          label={tabName}
          disableFocusRipple
          disableRipple
          classes={{ root: classes.tabsRoot, textColorPrimary: classes.textColorPrimary }}
        />
      ))}
    </MuiTabs>
  );
};

export default React.memo(Tabs);

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  tabNames: PropTypes.arrayOf(PropTypes.string).isRequired
};
