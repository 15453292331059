import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UploadModal from 'components/Wizards/UploadModal';
import { UploadFiles, DeleteFile } from 'helpers/firebaseStorage';
import useFireStorage from 'hooks/useFireStorage';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import ProviderFiles from './ProviderFiles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: theme.fonts.openSans,
    color: theme.palette.text.grey
  },
  inner: {
    padding: theme.spacing(3),
    display: 'flex'
  },
  paper: {
    width: '100%',
    minHeight: 220,
    boxShadow: '0px 3px 14px #0000000F',
    marginBottom: theme.spacing(4),
    borderRadius: 5
  },
  icon: {
    fontSize: 30,
    color: '#7D7D7D',
    cursor: 'pointer'
  },
  menu: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 5
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItem: {
    width: 200,
    height: 50,
    '&:hover': {
      background: '#F2F3F7'
    },
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  iconAction: {
    marginRight: 12,
    color: '#7D7D7D'
  }
}));

const Provider = ({ item, handleOpenEditForm, handleDelete, pathName }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { filesLoading, files, refresh } = useFireStorage(pathName);

  const fileUpload = () => {
    setUploading(true);
    UploadFiles(acceptedFiles, pathName)
      .then(() => {
        setUploading(false);
        setUploadOpen(false);
        refresh();
        toast.success('File Uploaded');
      })
      .catch(() => {
        setUploading(false);
        toast.error('Something Went wrong');
      });
  };

  const fileDelete = (name) => {
    setUploading(true);

    DeleteFile(name, pathName).then(() => {
      refresh();
      setUploading(false);
      toast.success('File Deleted');
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAttachFiles = useCallback(() => {
    setAcceptedFiles([]);
    setUploadOpen(true);
    handleClose();
  }, []);

  const handleEdit = useCallback(() => {
    handleOpenEditForm(item);
    handleClose();
  }, []);

  const handleOpen = useCallback(() => {
    setUploadOpen(true);
    handleClose();
  }, []);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.inner}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <Typography className={classes.text}>{item.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Box minHeight={70}>
              <Typography className={classes.text}>{item.description}</Typography>
            </Box>

            <ProviderFiles
              files={files.files}
              handleOpen={handleOpen}
              filesLoading={filesLoading}
              handleDelete={fileDelete}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Box display="flex" justifyContent="flex-end">
              <MoreHorizIcon className={classes.icon} onClick={handleClick} />
              <Menu
                id="actions-cost-manager-procurement"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menu, list: classes.menuList }}
                elevation={0}
              >
                <MenuItem className={classes.listItem} onClick={handleEdit}>
                  <EditIcon className={classes.iconAction} />
                  Edit
                </MenuItem>
                <MenuItem className={classes.listItem} onClick={handleAttachFiles}>
                  <AttachFileIcon className={classes.iconAction} />
                  Add File
                </MenuItem>
                <MenuItem className={classes.listItem} onClick={() => handleDelete(item.id)}>
                  <DeleteIcon className={classes.iconAction} />
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <UploadModal
        setAcceptedFiles={setAcceptedFiles}
        open={uploadOpen}
        handleClose={() => setUploadOpen(false)}
        acceptedFiles={acceptedFiles}
        handleSave={fileUpload}
        loading={uploading}
      />
    </Paper>
  );
};

export default React.memo(Provider);

Provider.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleOpenEditForm: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pathName: PropTypes.string.isRequired
};
