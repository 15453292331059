import 'date-fns';
import React from 'react';

import { InputAdornment, FormLabel, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    '& .MuiFormControl-root .MuiOutlinedInput-input': {
      fontFamily: 'Open Sans !important',
      color: '#5C5C5C'
    }
  },
  datePker: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  }
}));

const DatePickerComponent = ({ label, minDate, maxDate, maxErrorText, minErrorText, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    (<FormControl
      variant="standard"
      className={classes.root}
      error={meta.touched && !!meta.error}>
      <DatePicker
        variant="inline"
        minDate={minDate}
        maxDate={maxDate}
        format="dd MMM yyyy"
        margin="normal"
        inputVariant="outlined"
        label={label}
        maxDateMessage={maxErrorText}
        minDateMessage={minErrorText}
        autoOk
        fullWidth
        style={{ marginTop: 0 }}
        className={classes.datePker}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon style={{ color: '#9D9D9D' }} />
            </InputAdornment>
          )
        }}
        {...props}
        {...field}
        value={(field.value && new Date(field.value)) || null}
        onChange={(value) => setFieldValue(field.name, value)}
      />
      {meta.touched && meta.error ? (
        <FormLabel basic color="red">
          {meta.error}
        </FormLabel>
      ) : null}
    </FormControl>)
  );
};

export default DatePickerComponent;

DatePickerComponent.defaultProps = {
  minDate: new Date('1989-02-02'),
  maxDate: new Date('2050-01-02'),
  maxErrorText: 'Date Cannot be after end date',
  minErrorText: 'Date Cannot be before start date'
};

DatePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxErrorText: PropTypes.string,
  minErrorText: PropTypes.string
};
