import { facilityStatus, legalCategories } from 'constants/index';

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditButton from 'components/Buttons/EditButton';
import { openModal } from 'components/Modal/modalReducer';
import { formatDate, lowerCaseAllWordsExceptFirstLetters } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'styles/costManager';

// eslint-disable-next-line react/prop-types
function DetailsItem({ label, value }) {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.subtitle}>{label}</Typography>
      <Typography className={classes.subtitle2}>{value || 'N/A'}</Typography>
    </Box>
  );
}

export default function Header({ details, handleDelete, handleSave, handleSuccess }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const status = facilityStatus.find((item) => details.status === item.id)?.name || 0;
  const category = legalCategories.find((item) => details.category === item.id)?.name || 0;

  return (
    <>
      <Box padding={2} margin={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.responsive}
        >
          <Typography variant="h5" className={classes.headerText}>
            {`Case #${details.caseref}`}
          </Typography>
          <Box display="flex" alignItems="center">
            <DeleteIcon className={classes.editIcon} onClick={() => handleDelete(details.id)} />
            <EditButton
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: 'LegalTrackerModal',
                    modalProps: {
                      legalTracker: details,
                      handleSave,
                      handleSuccess
                    }
                  })
                )
              }
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" pt={2} flexWrap="wrap">
          <Box className={classes.box}>
            <Typography className={classes.subtitle}>Status</Typography>
            <Box display="flex" alignItems="center">
              <div
                className={classes.circle}
                style={details.status === 0 ? { background: '#01B231' } : { background: '#D9D9D9' }}
              />
              <Typography
                style={details.status === 0 ? { color: '#01B231' } : { color: '#AAAAAA' }}
                className={classes.subtitle2}
              >
                {lowerCaseAllWordsExceptFirstLetters(status)}
              </Typography>
            </Box>
          </Box>

          <DetailsItem label="Date Created" value={formatDate(details.datecreated) || 'N/A'} />
          <DetailsItem label="Expected Date" value={formatDate(details.expecteddate) || 'N/A'} />

          <Box className={classes.box}>
            <Typography className={classes.subtitle}>Assigned to</Typography>
            {users && users.length > 0 && (
              <Typography className={classes.subtitle2}>
                {details.userid
                  ? users.find((user) => user.id === details.userid)?.displayname
                  : 'N/A'}
              </Typography>
            )}
          </Box>

          <DetailsItem
            label="Category"
            value={lowerCaseAllWordsExceptFirstLetters(category) || 'N/A'}
          />
          <DetailsItem label="For/Against" value={details.foragainst ? 'For' : 'Against'} />
        </Box>
      </Box>
    </>
  );
}

Header.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired
};
