import React from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as HourglassIcon } from 'assets/hourglass_icon.svg';
import { ReactComponent as TilesIcon } from 'assets/tiles_icon.svg';
import clsx from 'clsx';
import EditButton from 'components/Buttons/EditButton';
import { openModal } from 'components/Modal/modalReducer';
import { formatZillions } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data: asset } = useSelector((state) => state.assets.assetDetails);
  const { assetKpi } = useSelector((state) => state.assets.assetKpi);

  return (
    <Paper className={classes.marginBottom}>
      <Box className={classes.padding} display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          <Typography variant="h5" className={classes.headerText}>
            {asset.name}
          </Typography>
          <Box className={classes.padding}>
            <EditButton
              className={classes.edit}
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: 'AssetModal',
                    modalProps: { asset }
                  })
                )
              }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" className={classes.marginBottom}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.infoBox}
              style={{ minWidth: 100 }}
            >
              <LocationOnIcon className={clsx(classes.icon, classes.marginRight)} />
              <Typography className={classes.width}>
                {asset.country.includes('United Kingdom') ? 'UK' : asset.country}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" className={classes.infoBox}>
              <ApartmentIcon className={clsx(classes.icon, classes.marginRight)} />
              <Typography className={classes.width}>{asset.type}</Typography>
            </Box>
            <Box display="flex" className={classes.infoBox}>
              <AccountBalanceWalletIcon className={clsx(classes.icon, classes.marginRight)} />
              <Typography className={classes.width}>
                {`Annual Rent €${formatZillions(assetKpi.annualrent)}`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" className={classes.infoBox} style={{ minWidth: 100 }}>
              <AccountCircleIcon className={clsx(classes.icon, classes.marginRight)} />
              <Typography className={classes.width}>
                {`Tenants ${assetKpi?.tenantcount || 0}`}
              </Typography>
            </Box>
            <Box display="flex" className={classes.infoBox}>
              <TilesIcon />
              <Typography className={classes.width}>
                {`GLA ${formatZillions(assetKpi?.totalgla) || 0} sqm`}
              </Typography>
            </Box>
            <Box display="flex" className={classes.infoBox}>
              <HourglassIcon />
              <Typography className={classes.width}>
                {`Occupancy ${assetKpi?.occupancyrate || 0}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
