import React, { Suspense } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Breadcrumps from 'components/Breadcrumps';
import Spinner from 'components/Spinner';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const UsersTable = React.lazy(() => import('./UserTable'));

const makeBundleId = (page) => `Page${page}`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%'
  },
  circle: {
    height: theme.spacing(1.8),
    width: theme.spacing(1.8),
    borderRadius: theme.spacing(1)
  },
  status: {
    display: 'flex',
    alignItems: 'center'
  },
  statusText: {
    paddingLeft: 10
  }
}));

const Users = () => {
  const usersData = useSelector((state) => state.users.users);
  const usersLoading = useSelector((state) => state.users.loading);
  const userRoles = useSelector((state) => state.user.userRoles);
  const userTotalCount = useSelector((state) => state.users.userTotalCount);
  const usersError = useSelector((state) => state.users.error);
  const { page, setPage } = useUrlParams();
  const bundleIds = useSelector((state) => state.users.byId);

  const dispatch = useDispatch();
  const classes = useStyles();
  const columns = [
    { title: 'Full Name', field: 'displayname' },
    {
      title: 'Role',
      field: 'role',
      render: (rowData) => (
        <div>
          <span className={classes.statusText}>
            {userRoles.find((userRole) => userRole.id === rowData.roleid)?.roletitle ?? ''}
          </span>
        </div>
      )
    },
    {
      title: 'Status',
      field: 'active',
      render: (rowData) => (
        <div className={classes.status}>
          <div
            className={classes.circle}
            style={rowData.active ? { backgroundColor: '#01B231' } : { backgroundColor: '#FD6565' }}
          />
          <span className={classes.statusText}>{rowData.active ? 'Active' : 'Inactive'}</span>
        </div>
      )
    },
    { title: 'Email', field: 'email' }
  ];

  React.useEffect(() => {
    const bundleId = makeBundleId(page);

    if (bundleIds.some((bundle) => bundle === bundleId)) {
      dispatch(actions.setUsersBundle(bundleId));
    } else {
      dispatch(actions.getAllUsers(page, bundleId));
    }
  }, [page]);

  if (usersError) {
    return (
      <div className={classes.root}>
        <Typography color="error" variant="h5">
          {usersError}
        </Typography>
      </div>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Breadcrumps />
      <UsersTable
        title={`Users (${userTotalCount})`}
        columns={columns}
        data={usersData}
        loading={usersLoading}
        setPage={setPage}
        page={page}
        count={userTotalCount}
      />
    </Suspense>
  );
};

export default Users;
