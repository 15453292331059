import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useStyles from '../styles';

const ChartRow = () => {
  const classes = useStyles();

  return (
    (<Box display="flex" pl={3} mt={3} mb={-2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box bgcolor="#5381EF" width={12} height={12} borderRadius="6px" />
        <Typography className={classes.legend}>Income</Typography>
      </Box>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box bgcolor="#52CED5" width={12} height={12} borderRadius="6px" />
          <Typography className={classes.legend}>Expenses</Typography>
        </Box>
      </Box>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box bgcolor="#FEAB29" width={12} height={12} borderRadius="6px" />
          <Typography className={classes.legend}>Net Profitability</Typography>
        </Box>
      </Box>
    </Box>)
  );
};

export default ChartRow;
