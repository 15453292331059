import {getEnviroment} from 'config/config';

import {
    AssetServiceClient,
    CostContractDetailsServiceClient,
    CostManagerServiceClient,
    CostManagerServicePromiseClient,
    CostProviderServiceClient,
    CostSupplierDetailsServiceClient,
    FacilityManagerTicketServiceClient,
    FacilityManagerTicketServicePromiseClient,
    FootfallServiceClient,
    LegalTrackerTicketServiceClient,
    LegalTrackerTicketServicePromiseClient,
    OpexContractDetailsServiceClient,
    OpexManagerServiceClient,
    OpexProviderServiceClient,
    OpexSupplierDetailsServiceClient,
    ValuationServiceClient
} from '../../api/asset_grpc_web_pb';
import {UnitServiceClient, UnitServicePromiseClient} from '../../api/unit_grpc_web_pb';
import {enableDevTools} from "./webtool";

const env = localStorage.getItem('env');

const ENV = getEnviroment(env);

export const AssetService = new AssetServiceClient(ENV);
export const UnitService = new UnitServiceClient(ENV);
export const UnitPromiseService = new UnitServicePromiseClient(ENV);
export const FootfallService = new FootfallServiceClient(ENV);
export const CostManagerServicePromise = new CostManagerServicePromiseClient(ENV);
export const CostManagerService = new CostManagerServiceClient(ENV);
export const CostProcurementService = new CostProviderServiceClient(ENV);
export const CostSupplierService = new CostSupplierDetailsServiceClient(ENV);
export const CostContractDetailsService = new CostContractDetailsServiceClient(ENV);
export const OpexManagerService = new OpexManagerServiceClient(ENV);
export const OpexManagerProcurementService = new OpexProviderServiceClient(ENV);
export const OpexSupplierService = new OpexSupplierDetailsServiceClient(ENV);
export const OpexContractService = new OpexContractDetailsServiceClient(ENV);
export const ValuationService = new ValuationServiceClient(ENV);
export const FacilityService = new FacilityManagerTicketServicePromiseClient(ENV);
export const FacilityManagementService = new FacilityManagerTicketServiceClient(ENV);
export const LegalService = new LegalTrackerTicketServiceClient(ENV);
export const LegalMangementService = new LegalTrackerTicketServicePromiseClient(ENV);

enableDevTools([
    AssetService,
    UnitService,
    UnitPromiseService,
    FootfallService,
    CostManagerServicePromise,
    CostManagerService,
    CostProcurementService,
    CostSupplierService,
    CostContractDetailsService,
    OpexManagerService,
    OpexManagerProcurementService,
    OpexSupplierService,
    OpexContractService,
    ValuationService,
    FacilityService,
    FacilityManagementService,
    LegalService,
    LegalMangementService
]);