/* eslint-disable react/prop-types */
import React, { useState, memo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EditButton from 'components/Buttons/EditButton';
import Spinner from 'components/Spinner';
import { useSelector } from 'react-redux';

import useStyles from '../styles';

import EditForm from './LeasingTenantDetailsEdit';

const BoxItem = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.boxInner}>
      <Typography className={classes.statsTitle}>{title}</Typography>
      <Typography className={classes.statsValue}>{value || 'N/A'}</Typography>
    </Box>
  );
};

const LeasingTenantDetails = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    loaded,
    loading,
    details: tenant,
    error
  } = useSelector((state) => state.leasingManager.details);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!loaded || loading) {
    return <Spinner mini />;
  }

  if (loaded && error) {
    return (
      <Typography color="error" style={{ marginLeft: 30, marginTop: 30 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Grow in>
      <Box>
        <Paper>
          <Box>
            <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.responsive}>
              <Typography className={classes.title}>Tenant Details</Typography>
              <EditButton onClick={handleClickOpen} />
            </Box>
            <EditForm open={open} handleClose={handleClose} tenant={tenant} />

            <Box display="flex" justifyContent="center">
              <Divider className={classes.divider} />
            </Box>
            <Box flexWrap="wrap" display="flex" className={classes.boxContainer}>
              <BoxItem value={tenant.maincontact} title="Main Contact" />
              <BoxItem value={tenant.position} title="Position" />
              <BoxItem value={tenant.email} title="E-mail" />
              <BoxItem value={tenant.headofficeaddress} title="Head Office Address" />
              <BoxItem value={tenant.phone} title="Phone" />
              <BoxItem value={tenant.notes} title="Notes" />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grow>
  );
};

export default memo(LeasingTenantDetails);
