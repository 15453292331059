import React, { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from 'components/Filters/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fonts.base,
    fontSize: 25
  },
  reset: {
    marginLeft: theme.spacing(3),
    color: '#5381EF',
    fontFamily: theme.fonts.base,
    cursor: 'pointer'
  },
  closeIcon: {
    cursor: 'pointer',
    color: '#5C5C5C'
  }
}));

const SideFilters = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const portfolios = useSelector((state) => state.portfolios.portfolios);
  const { asset, portfolio } = useSelector((state) => state.alerts.filters);
  const [assets, setAssets] = useState([]);

  const resetFilters = () => {
    dispatch(
      actions.setAlertFilters({
        asset: '',
        portfolio: '',
        tenant: ''
      })
    );
  };

  const fetchAssets = (portfolioId) => {
    dispatch(
      actions.setAlertFilters({
        asset: '',
        portfolio: portfolioId,
        tenant: ''
      })
    );
  };

  useEffect(() => {
    if (!portfolio) return;

    dispatch(actions.getAssetsByPortfolio([portfolio])).then((res) => {
      setAssets(res);
    });
  }, [portfolio, asset]);

  return (
    <Box display="flex" minHeight={250} flexDirection="column">
      <Box display="flex" justifyContent="space-between" p={2} alignItems="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography className={classes.title}>Alerts</Typography>

          <Typography className={classes.reset} onClick={resetFilters}>
            RESET FILTERS
          </Typography>
        </Box>

        <CloseIcon
          className={classes.closeIcon}
          onClick={() => dispatch(actions.toggleAlertMenu())}
        />
      </Box>

      <Box mt={4} display="flex" flexDirection="column" justifyContent="center">
        <Dropdown
          items={portfolios}
          value={portfolio}
          handleChange={(e) => fetchAssets(e.target.value)}
          label="Portfolio"
          keyword="name"
        />

        <Dropdown
          items={assets}
          value={asset}
          handleChange={(e) =>
            dispatch(
              actions.setAlertFilters({
                asset: e.target.value
              })
            )
          }
          label="Asset"
          keyword="name"
          disabled={portfolio === ''}
        />
      </Box>
    </Box>
  );
};

export default SideFilters;
