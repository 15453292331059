import React from 'react';

import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  iconWhite: {
    color: '#FFF'
  },
  colorBox: {
    backgroundColor: '#5381EF',
    borderRadius: '5px',
    width: '64px',
    height: '64px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  marginRight: {
    margin: '0 48px 0 0'
  },
  paddingLeft: {
    padding: '0 0 0 8px'
  },
  marginBottom: {
    margin: '0 0 16px 0'
  },
  paddingY: {
    padding: theme.spacing(2, 0)
  },
  boxHeading: {
    margin: theme.spacing(1, 0),
    color: '#666'
  },
  color: { color: '#666' },
  box: {
    textDecoration: 'none'
  }
}));

const InfoPanel = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.paddingY}>
      <Box className={classes.boxHeading}>
        <Typography variant="h5">Statistics</Typography>
      </Box>
      <Box display="flex">
        <BoxItem
          name="Portfolios"
          to="/Portfolios"
          count={data.portfolioCount}
          icon={FolderSharedIcon}
        />
        <BoxItem name="Assets" to="/Assets" count={data.assetCount} icon={LocationOnIcon} />
        <BoxItem name="Tenants" to="/Tenants" count={data.tenantCount} icon={PeopleIcon} />
      </Box>
    </Box>
  );
};

export default InfoPanel;

InfoPanel.propTypes = {
  data: PropTypes.object.isRequired
};

const BoxItem = ({ icon: Icon, count, name, to }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.marginRight, classes.box)}
      component={Link}
      to={to}
    >
      <Box className={classes.colorBox} display="flex" justifyContent="center" alignItems="center">
        <Icon className={classes.iconWhite} fontSize="large" />
      </Box>
      <Box display="flex" flexDirection="column" className={classes.paddingLeft}>
        <Typography variant="subtitle2" className={classes.color}>
          {name}
        </Typography>
        <Typography variant="h4" className={classes.color}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

BoxItem.propTypes = {
  icon: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};
