/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import App from 'App';
import { ReactComponent as FolderIcon } from 'assets/folder.svg';
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/analytics.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/menu-down.svg';
import Logo from 'assets/logo.svg';
import SearchInput from 'components/Search';
import useIsAdmin from 'helpers/isAdmin';
import UserHasAccess from 'hooks/UserHasAccess';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as actions from 'store/actions';

import SideBar from './Sidebar';
import useStyles from './styles';
import PeopleIcon from "@mui/icons-material/People";

const menuId = 'primary-search-account-menu';

const navLinks = [
  { path: '/', name: 'Home', rights: 'asset', icon: HomeIcon },
  { path: '/Portfolios', name: 'Portfolios', rights: 'portfolio', icon: FolderIcon },
  { path: '/Assets', name: 'Assets', rights: 'asset', icon: LocationIcon },
  { path: '/Tenants', name: 'Tenants', rights: 'asset', icon: PeopleIcon },
];

function ToolbarComponent({ handleProfileMenuOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.alerts);
  const userNotifications = useSelector((state) => state.alerts.alerts.length);
  const role = useSelector((state) => state.user.userPermissions.roletitle);
  const isAdmin = useIsAdmin();
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const assetAccess = UserHasAccess('asset', 'view', userPermissions);
  const { displayname } = useSelector((state) => state.user.user);
  const [drawerOpen, setDrawer] = useState(false);
  const [hover, setHover] = useState(false);

  const getNameInitials = useMemo(
    () => `${displayname?.split(' ')[0][0]}${displayname?.split(' ')[1][0]}`,
    [displayname]
  );

  return (<>
    <div className={classes.grow} style={{height: '64px'}}></div>
    {assetAccess && <SideBar open={open} setOpen={() => dispatch(actions.toggleAlertMenu())}/>}
    <Toolbar style={
      {
        height: 64,
        backgroundColor: '#1B2740',
        padding: 0,
        margin: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000
      }
    }>
      <div className={classes.navLinkContainer}>
        {role && (
            navLinks.map((link) =>
                <NavLink
                    exact
                    to={link.path}
                    className={`${classes.navLink} ${link.name}-nav`}
                    activeClassName={classes.activeLink}
                    key={link.name}
                    data-testid={`${link.name}-nav`}
                    data-cy={`${link.name}-nav`}
                >
                  <Typography className={classes.navText}>{link.name}</Typography>
                </NavLink>
            )
        )}
      </div>

      <div className={classes.growHorizontal}/>

      {role && !isAdmin && <SearchInput/>}

      <div className={classes.sectionDesktop}>
        {role && (
            <IconButton
              aria-label="show 14 new notifications"
              color="inherit"
              onClick={() => dispatch(actions.toggleAlertMenu())}
              classes={{root: classes.iconBtn}}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              disabled={!userNotifications}
              size="large">
              <Badge badgeContent={userNotifications} classes={{badge: classes.customBadge}}>
                {userNotifications > 0 && hover ? (
                    <NotificationsIcon/>
                ) : (
                    <NotificationsNoneIcon
                        style={{
                          color: userNotifications > 0 ? '#FFF' : '#999'
                        }}
                    />
                )}
              </Badge>
            </IconButton>
        )}
        <Avatar
            variant="circular"
            className={classes.blue}
            onClick={handleProfileMenuOpen}
            aria-controls={menuId}
        >
          {getNameInitials}
        </Avatar>
      </div>
    </Toolbar>
  </>);
}

export default React.memo(ToolbarComponent);
